import AttributeRuleModel from "./AttributeRuleModel";



class StringRule extends AttributeRuleModel {

  constructor(ruleset={}) {
    super();

    const { flags=[], rules={} } = ruleset;
    this.parseFlags(flags);
    this.parseRules(rules);
  }
}


export default StringRule;


