import React from "react";
import { _ajax } from "../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import {deGuidify, createPhenomGuid, scrubNodeExport} from "../util/util";
import {getNodesOfType, modelDeprecateNode, getNodeWithAddenda, verifyEquation, getThingsOfType} from "../../requests/sml-requests";
import {CadetInput, LineLabel, CadetTextArea, SingleSelect, Card, PhenomComboBox} from "../util/stateless";
import BasicEditor from '../util/templates/basic_editor';
import {NodeHistory2} from "../edit/node-history";
import {Notifications, Notifications2} from '../edit/notifications';
import {Tags} from "../util/tags";
import {Button} from "@progress/kendo-react-buttons";
import styled from "@emotion/styled";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "../edit/node-layout";
import { PhenomLink } from "../widget/PhenomLink";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import {EquationDisplay} from "../util/equation_display";
import { createNodeUrl } from "../../requests/type-to-path";
import NavTree from "../tree/NavTree";
import { getActiveChangeSetId } from "../../requests/actionCreators";



export class Conversion extends React.Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      name: "",
      guid: undefined,
      description: "",
      conversionLossDescription: "",
      source: "",
      target: "",
      equation: "",
      deprecated: "false",
      xmiType: "new",
      referencables: [],
      editable: true,
      eqLoss: "",
      parent: null,
      parentPackages: [{name: "loading..."}],
      observables: {},
      observable: {},
    };
    this.state = this.defaultState;
    this.original = undefined;
    this.noticeRef = React.createRef();
    this.historyRef = React.createRef();
    this.typeNameMap = {
      "logical:MeasurementConversion": "Measurement",
      "logical:MeasurementSystemConversion": "Measurement System",
    }
  }



  componentDidMount() {
    this.getData();
    if (this.props.match.params.guid === "new") {
        this.original = this.defaultState;
        this.setupNewNode();
    } else {
        this.setupExistingNode();
    }
  }

  componentDidUpdate(prevProps, prevState) {
      const currGuid = this.props.match.params.guid;
      const prevGuid = prevProps.match.params.guid;
      if (currGuid !== prevGuid) {
          if (currGuid == "new") {
            this.setupNewNode();
          } else {
            this.setupExistingNode();
         }
      }
  }

 getData = () => {
   getThingsOfType("face:LogicalDataModel").then((res) => {
       const response = JSON.parse(res).elements;
       this.setState({parentPackages: response ? response.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []});
   });

   getNodesOfType("conceptual:Observable").then(res => {
       this.setState({observables: deGuidify(res.data.nodes)});
   })

 }

  setupNewNode = async () => {
    await this.setState(this.defaultState);
    this.getData()
  }

  setupExistingNode = async () => {
    const response = await getNodeWithAddenda(this.props.match.params.guid, {
        coreAddenda: ["source", "target"],
    });
    this.original = response;
    this.getReferenceables(response.xmiType);

    if (response.guid) {
      this.setState({
        guid: response.guid,
        parent: response.parent,
        name: response.name,
        description: response.description,
        conversionLossDescription: response.conversionLossDescription,
        source: response.source.guid,
        target: response.target.guid,
        equation: response.equation,
        deprecated: response.deprecated,
        xmiType: response.xmiType,
        editable: true,
      })
    } else {
      // Notifications2.parseResponse(response);
    }
  }


  handleSave = () => {

    // if not changed; TODO
    if (this.state.xmiType == "new") {
      Notifications2.parseErrors(["ConversionType must be selected."]);
      return;
    }
    const requestData = {
      guid: this.state.guid || createPhenomGuid(),
      parent: this.state.parent,
      name: this.state.name,
      xmiType: this.state.xmiType,
      source: this.state.source,
      target: this.state.target,
      equation: this.state.equation,
      description: this.state.description,
      conversionLossDescription: this.state.conversionLossDescription,
      changeSetId: getActiveChangeSetId(),
    }

    if (this.props.match.params.guid != "new") {
      requestData["guid"] =  this.state.guid;
    }

    return _ajax({
        url: "/index.php?r=/node/smm-save-nodes",
        method: "post",
        data: requestData
    }).then(res => {
        const response = res.data;
        if (response.guid) {
            if (!this.state.guid) {
                this.props.history.push( createNodeUrl(response) );
            }
            NavTree.addNodes([ response ]);
        }
        Notifications2.parseResponse(response);
    });
  }

  getReferenceables = (type) => {
    let referencables;
    let refType = false;
    switch (type) {
      case "logical:MeasurementConversion":
        refType = "logical:Measurement";
        break;
      case "logical:MeasurementSystemConversion":
        refType = "logical:MeasurementSystem";
        break;
      case "new":
        break;
      default:
        this.noticeRef.error("Unrecognized xmi:type");
    };
    getNodesOfType(refType, {coreAddenda : ["reazlizes"]})
    .then(res => {
        referencables = res.data.nodes
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
          .filter(ref => {
            return (refType == "logical:Measurement" && this.state.observable?.guid) ? ref.realizes == this.state.observable.guid : true;
          });
        this.setState({referencables: referencables});
    });
  }

  handleDelete = () => {
    DeletionConfirm2.show(this.state.guid, this.state.name, this.handleReset);
  }

  addDeprecation = guid => {
      modelDeprecateNode(guid, getActiveChangeSetId()).then((res) => {
          this.handleReset();
          this.noticeRef.info(`${this.state.name} has been deprecated.`);
      });
  }

  handleReset = () => {
    this.setupExistingNode()
  }

  setConversionType = (type) => {
    this.setState({xmiType: type, source: "", target: ""}, () => this.getReferenceables(type));
  }

  handleObsChange = (obsGuid) => {
    this.setState({ "observable": !!obsGuid ? this.state.observables[obsGuid] : {}, source: "", target: "" }, () => this.getReferenceables(this.state.xmiType))
  }


  render() {
    const deprecated = this.state.deprecated === "true";
    const editable = this.state.editable && !deprecated;
    const isNew = this.props.match.params.guid === "new";
    const labelStyle = {margin:"0 0 7px", lineHeight:"normal"};
    const phenomId = new PhenomId("conversion",this.props.idCtx);
    return (
      <BasicEditor guid={this.state.guid}
                   idCtx={phenomId.genPageId()}
                   nodeTitle={(this.typeNameMap[this.state.xmiType] || "") + " Conversion"}
                   nodeName={this.state.name}
                   onNameChange={(val) => this.setState({name: val})}
                   nodeDescription={this.state.description}
                   onDescriptionChange={(val) => this.setState({description: val})}
                  //  saveBtn={this.save}
                  //  resetBtn={this.resetConversion}
                  //  deleteBtn={this.deleteConversion}
                   noticeRef={this.noticeRef}
                   historyRef={this.historyRef}>
        <div className={"flex-h"}>
          <div className={"flex-v"} style={{flex: 1}}>
            <LineLabel text={"Conversion Type"} idCtx={phenomId.gen("","type")} style={{paddingRight: "15px", flex:"1"}}/>
            <select
                id={phenomId.gen("type","select")}
                className="cadet-select"
                value={this.state.xmiType}
                disabled={!isNew}
                onChange={(e) => this.setConversionType(e.target.value)}>
                <option id={phenomId.gen(["type","default"],"option")} value='new'>No Type Selected</option>
                {Object.entries(this.typeNameMap).map((entry, idx) => {
                  return (<option value={entry[0]} id={phenomId.gen(["type",idx],"option")} key={idx}>{entry[1]}</option>);
                })}
            </select>
          </div>
          {this.state.xmiType == "logical:MeasurementConversion" && <div style={{paddingRight: "15px", flex: 1}}>
            <LineLabel text="Observable" idCtx={phenomId.gen("source","observable")}/>
            <select
                id={phenomId.gen("target","observable-select")}
                className="cadet-select"
                disabled={!editable}
                value={this.state.observable?.guid}
                onChange={(e) => this.handleObsChange(e.target.value)}>
                <option id={phenomId.gen(["target","default"],"option")} value={null}>All</option>
                {Object.values(this.state.observables).map((obs, idx) => {
                  return (<option value={obs.guid} key={idx} id={phenomId.gen(["target",idx],"option")}>{obs.name}</option>)})}
            </select>
          </div>}
          <div className={"flex-v"} style={{flex: 1}}>
            <LineLabel text="Parent Package" idCtx={phenomId.gen("","parent-package")}/>
            <select
                id={phenomId.gen("parent-package","select")}
                className="cadet-select"
                value={this.state.parent}
                disabled={!isNew}
                onChange={(e) => this.setState({parent: e.target.value})}>
                <option id={phenomId.gen(["parent-package","default"],"option")} value={null}>Default (PhenomConversions)</option>
                {this.state.parentPackages.map((pack, idx) => {
                    return (<option value={pack.guid} id={phenomId.gen(["parent-package",idx],"option")} key={idx}>{pack.name}</option>);
                })}
            </select>
          </div>
        </div>
        <div className={"flex-h"} style={{}}>
          <div style={{flex: 1, paddingRight:"15px"}}>
            <PhenomComboBox
              id={phenomId.gen("init","source")}
              label="Source"
              value={this.state.referencables.find(ref => ref.guid == this.state.source)}
              data={this.state.referencables}
              dataItemKey="guid"
              onChange={(ref) => this.setState({source: ref.guid})}
              onClickCancelIcon={() => this.setState({source: ""})} />
          </div>
          <div style={{flex: 1}}>
          <PhenomComboBox
            id={phenomId.gen("init","target")}
            label="Target"
            value={this.state.referencables.find(ref => ref.guid == this.state.target)}
            data={this.state.referencables}
            dataItemKey="guid"
            onChange={(ref) => this.setState({target: ref.guid})}
            onClickCancelIcon={() => this.setState({target: ""})} />
          </div>
        </div>
        <EquationDisplay
          equation={this.state.equation}
          setEquation={(e) => this.setState({equation: e.target.value})}
          source={this.state.source}
          target={this.state.target}
          editable={editable}/>
        <LineLabel text="Conversion Loss" idCtx={phenomId.gen("","conversion-loss")}/>
        <CadetInput text={this.state.conversionLossDescription} onChange={(e) => this.setState({conversionLossDescription: e.target.value})}
            disabled={!editable}
            idCtx={phenomId.gen("","conversionLossDescription")}/>
      <Tags guid={this.state.guid} name={this.state.name} disabled={!editable} idCtx={phenomId.genPageId()}/>
      </BasicEditor>
    )
  }

}

export const EditConversionManager = withPageLayout(Conversion);
