import AttributeRuleModel from "./AttributeRuleModel";


class EnumerationRule extends AttributeRuleModel {
  enum = [];

  constructor(ruleset={}) {
    super();

    const { flags=[], rules={} } = ruleset;
    this.parseFlags(flags);
    this.parseRules(rules);
  }

  parseRules(rules = {}) {
    
    if (!rules) {
      return;
    }

    super.parseRules(rules);
      
    Object.keys(rules).forEach((key) => {
      const value = rules[key];
  
      switch (key) {
        case "in":
          if (Array.isArray(value) && value.every(val => typeof val === 'string')) {
            this.enum = value;
          }
          break;
        
        // case bound:
        default:
      }
    })
  }

  getEnumeration() {
    return this.enum;
  }
}



export default EnumerationRule;