import React from "react";
import { PhenomSelect } from "../util/stateless";
import { useSelector } from "react-redux";
import { receiveChangeSetId } from "../../requests/actionCreators";


const ChangeSetPicker = (props) => {
  const changeSetList = useSelector(state => state.app.changeSets);
  const activeChangeSetId = useSelector(state => state.app.activeChangeSetId);
  
  const handleChange = (e) => {
    receiveChangeSetId(e.target.value);
  }
  
  return <PhenomSelect id={props.id ? `${props.id}-changeset` : null}
                       label={props.label}
                       value={activeChangeSetId}
                       data={changeSetList}
                       dataItemKey="id"
                       style={props.style}
                       disabled={props.disabled}
                       autoComplete={props.autoComplete}
                       onChange={handleChange} />
}


export default ChangeSetPicker;

