import React from "react";
import { AcceptForMerge, RejectForMerge, KbButton, PhenomLabel } from "../../util/stateless";
import { SubMenuRight } from "../../edit/edit-top-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import ListPaneView from "../../edit/list-pane-view";
import NavTree from "../../tree/NavTree";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { PhenomLink } from "../../widget/PhenomLink";
import LoaderButton from "../../widget/LoaderButton"
import { finalizeMergeReview, toggleReviewNodeStatus, rejectReviewNodes, unlockProject, isProjectLocked } from "../../../requests/sml-requests";
import { receiveResponse } from "../../../requests/actionCreators";
import { resetApp } from "../../../requests/actionThunks";
import { ReviewSubMenu } from "../review";
import { after, cloneDeep } from "lodash";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { connect } from "react-redux";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif"

export function acceptReviewNode(guid, thisNodeOnly, callBack=false) {
    return toggleReviewNodeStatus(guid, thisNodeOnly).then((res) => {
        receiveResponse(res);
        NavTree.reset().then(()=> callBack && callBack());
    });
}

export function rejectReviewNode(guid, thisNodeOnly, skipValidation, commitBefore, callBack=false) {
    return rejectReviewNodes(guid, thisNodeOnly, skipValidation, commitBefore).then((res) => {
        receiveResponse(res);
        NavTree.reset().then(()=> callBack && callBack());
    });
}

export function addParentForPhenomLink(node) {
    if (!node) return;
    
    const addParentTypes = [
        "platform:CharacteristicProjection",
        "conceptual:Composition",
        "conceptual:AssociatedEntity",
        "platform:EnumerationLiteral",
        "platform:IDLComposition",
        "logical:EnumerationConstraint",
        "logical:ReferencePoint",
        "logical:EnumerationLabel",
        "logical:MeasurementAttribute",
        "logical:ReferencePointPart",
        "uop:MessagePort",
        "skayl:UnionCase",
    ];

    const addGrandparentTypes = [
        "skayl:UnionCase",
    ];
    
    if (addParentTypes.includes(node.xmiType)) {
        const parentLeaf = NavTree.getLeafNode(node.parent);
        if (!parentLeaf) return;

        node.parent = parentLeaf.getData();

        if (addGrandparentTypes.includes(node.xmiType)) {
            const grandparentLeaf = NavTree.getLeafNode(node.parent.parent);
            if (!grandparentLeaf) return;

            node.parent.parent = grandparentLeaf.getData();
        }
    }
}

export class FinalizeMerge extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            typeToNodeMap: null,
            loading: true,
            lists: [{ 
                collapsible: false,
                data: [],
                headerOnly: true,
                headerClass: "main",
                columns: [{header: "Review Nodes"}]
            }]
        }

        this.state={...this.defaultState}
    }

    componentDidMount() {
        NavTree.collapseNavTree(false);
        NavTree.reset().then(() => this.init());
    }
    
    componentDidUpdate(prevProps, prevState) {
        
    }

    renderReviewNodeOptions = (node) => {
        const buttonStyle = {
            fontSize: 18,
            height: "21px",
            width: "21px",
            border: "none",
            outline: "none",
            backgroundColor: "transparent"
        }

        return (
            <div style={{display: "flex", flexDirection: "row", gap: "1em"}}>
                <AcceptForMerge 
                    node={node} 
                    onAction={this.init}
                    hoverClass='hover-menu review-menu2'
                    buttonStyle={buttonStyle}
                />
                <RejectForMerge 
                    node={node}
                    onAction={this.init}
                    hoverClass='hover-menu review-menu2'
                    buttonStyle={buttonStyle}
                />
            </div>
        );
    }

    init = () => {
        const typeToNodeMap = {};
        let reviewNodes = cloneDeep(NavTree.getReviewNodeDataList());
        // push deleted rejections here
        if(!reviewNodes.length) return;

        // format nodes & create type map
        reviewNodes.forEach(node => {
            if (node.reviewStatus === 'Under_Review') {
                node.reviewStatus = 'Pending'
            }

            if (typeToNodeMap[node.xmiType]) {
                typeToNodeMap[node.xmiType].push(node);
            } else {
                typeToNodeMap[node.xmiType] = [node];
            }
        });

        const lists = [{ 
            collapsible: false,
            data: [...reviewNodes],
            headerOnly: true,
            headerClass: "main",
            columns: [{header: "Review Nodes"}]
        }]

        // create & sort lists for each xmiType
        Object.keys(typeToNodeMap).forEach((key) => {
            typeToNodeMap[key].sort((a, b) => a["name" || "rolename" || "xmiType"].localeCompare(b["name" || "rolename" || "xmiType"]));
            lists.push(
                { collapsible: true,
                    collapsed: false,
                    data: typeToNodeMap[key],
                    columns: [
                        {header: getShortenedStringRepresentationOfXmiType(key), 
                        render: (item) => { console.log(item)
                            return <div className="ellipses-link"><PhenomLink node={item} newTab={true} showParent={true} showGrandparent={true} /></div>
                        }, 
                        flex: 4}, 
                        {header: "Review Status", key: "reviewStatus", flex: 2},
                        {header: "", render: this.renderReviewNodeOptions , flex: 1},
                    ]
                }
            );
        });

        this.setState({lists: lists, loading: false});
    }

    handleFinalizeMerge = () => {
        const { activeProjectId } = this.props;

        this.setState({loading: true});

        const afterMerge = (res) => {
            receiveResponse(res);
            sessionStorage.clear();
            resetApp();
            NavTree.reset(true);
            this.setState({loading: false});
        }

        isProjectLocked(activeProjectId, true).then((res) => {
            BasicConfirm.show(
                "Are you sure you want to finalize the Merge?\n\nThis action cannot be undone.",
                () => {
                    const { locked_status } = res.data;

                    if(locked_status) {
                        BasicConfirm.show(
                            "The destination Project is currently locked, to complete the merge you must lift the lock.\n\nWould you like to unlock the destination Project?",
                            ()=>{
                                unlockProject(activeProjectId, true)
                                    .then(()=> {
                                        receiveResponse(res);
                                        finalizeMergeReview()
                                            .then((res) => afterMerge(res))
                                            .catch((err) => this.setState({loading: false}));
                                    }).catch((err) => this.setState({loading: false}));
                            },
                            ()=>{ this.setState({loading: false}) },
                            "Unlock Destination Project?"
                        )
                    } else {
                        finalizeMergeReview()
                            .then((res) => afterMerge(res))
                            .catch((err) => this.setState({loading: false}));
                    }
                },
                ()=>{ this.setState({loading: false}) },
            )
        });        
    }

    renderContentAboveList = () => {
        return (
            <>
                <PhenomLabel text={"Review Changes"} />
                <p>Changes under review may be accepted or reject from this page. Once changes are denied, they are removed from the active review project but remain accessable from the listing below.</p>
            </>
        );
    }

    render() {
        const { lists, loading } = this.state;

        return (
            <div className="phenom-content-wrapper"> 
                <ReviewSubMenu> 
                    <KbButton/>
                    {loading &&
                        <img id={"merge-loading-icon"}
                             style={{ display: "block", height: "30px" }}  src={loadingIcon}
                        />
                    }
                    <button
                        className="fas fa-merge"
                        title="Finalize Merge"
                        onClick={this.handleFinalizeMerge}
                        disabled={loading}
                    />
                </ReviewSubMenu>

                {/* Page Content Goes In This Div */}
                <div className="phenom-content-wrapper">
                    <div style={{margin: 20, overflowY: "hidden", height: "100%"}}>
                        <ListPaneView
                            mainKey={"id"}
                            lists={lists}
                            renderContentAboveList={this.renderContentAboveList}
                            minimizePane={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const msp = (state) => ({
    activeProjectId: state.user.userIdentity.activeProjectId,
  });

export default connect(msp)(FinalizeMerge);