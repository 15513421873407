import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import ReactTooltip from "react-tooltip";
import {cloneDeep} from 'lodash'

import {Notifications2} from "./notifications";
import {NodeHistory2} from "./node-history";
import { CoordinateSystemAxisManager } from "../util/coordinate_system_axis";
import {deGuidify, colors,  createPhenomGuid} from "../util/util";
import { ConversionsDisplay } from "../util/conversions_display";


import {
    elementDetail,
    getNodeWithAddenda,
    getNodesOfType,
    smmSaveNodes,
} from "../../requests/sml-requests";

import {
    CadetInput,
    CadetTextArea,
    LineLabel,
    SingleSelect,
    MultiSelect,
    Card,
    CommaLinks,
} from "../util/stateless";

import NodeLayout, { withPageLayout, StyledGrid, StyledInput, StyledSidebar, StyledContent, StyledCardGroup } from './node-layout'
import PhenomId from "../../requests/phenom-id";
import { BasicConfirm } from "../dialog/BasicConfirm";
import { createNodeUrl } from "../../requests/type-to-path";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import NavTree from "../tree/NavTree";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";


const labelStyle = {margin:"0 0 7px", lineHeight:"normal"};
const inputStyle = {margin:"0 0 10px"}

export class CoordinateSystemManager extends React.Component {
    original = {};
    csaRef = React.createRef();
    parentRef = React.createRef();
    newCsaRef = [];
    newCsaCount = 1;

    loadingText = "Loading...";

    defaultCoordinateSystem = {
      guid: null,
      xmiType: "logical:CoordinateSystem",
      name: "",
      description: "",
      angleEquation: "",
      distanceEquation: "",
      axisRelationshipDescription: "",
      axis: [],
      csaGuids: [],
      parent: "",
      related_measurement_systems: [],
      editable: true,
      newCSAs: [],
    }

    state = {
        ...cloneDeep(this.defaultCoordinateSystem),
    }

    styles = {
      newCSA: {
        position: "relative",
        border: `3px solid ${colors["logical:CoordinateSystemAxis"]}`,
        boxShadow: "3px 3px 6px #121212"
      },
      removeCSA: {
        position:"absolute",
        top:0,
        right:0,
        zIndex:1
      }
    }

    componentDidMount() {
        if (this.props.match.params.guid === "new") {
            this.initNewNode();
        } else {
            this.initNode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            ReactTooltip.rebuild();

            if (this.props.match.params.guid === "new") {
                this.initNewNode();
            } else {
                this.initNode();
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
              this.setEditingStatus();
        }
    }

    initNode = () => {
      getNodesOfType("logical:CoordinateSystemAxis").then(res => {
        this.setState({ coordinateSystemAxes: deGuidify(res.data.nodes) })
      })

      elementDetail(this.props.match.params.guid).then(res => {
        this.setState({
          related_measurement_systems: res.data.related_measurement_systems || []
        })
      })

      getNodeWithAddenda(this.props.match.params.guid).then(res => {

        this.setStateFromResponse(res);
      })
    }

    initNewNode = () => {
      getNodesOfType("logical:CoordinateSystemAxis").then(res => {
        this.setState({ coordinateSystemAxes: deGuidify(res.data.nodes) })
      })

      getNodesOfType("face:LogicalDataModel").then(res => {
        this.setState({ parents: deGuidify(res.data.nodes) })
      })

      this.setState({
        ...cloneDeep(this.defaultCoordinateSystem),
      })
    }

    setStateFromResponse = (response) => {
      const csaGuids = response.axis.split(" ");
      this.original = cloneDeep(response);
      
      if (this.props.updateTemplateNode) {
        this.props.updateTemplateNode(response);
      }

      this.setState({ ...response, csaGuids }, () => {
        this.setEditingStatus();
      });
    }

    handleSave = () => {
        const csaRef = this.csaRef.current;

        let parentGuid = null;
        let axis = [];

        if (this.props.match.params.guid === "new") {
            parentGuid = this.parentRef.current.generateGuid();
        }

        // add inline CSAs
        if (this.props.match.params.guid === "new" && this.newCsaRef.length) {
          this.newCsaRef.forEach(ref => {
            if(!ref) return;

            axis.push(ref.generateNode());
          })
        }

        // if inline CSAs exist create nodes, otherwise use Guids
        if(axis.length) {
          axis = axis.concat(csaRef.generateNodes());
        } else {
          axis = csaRef.generateGuids();
        }

        // Setup Coordinate System request data
        let node = {
            guid: this.state.guid || createPhenomGuid(),
            name: this.state.name,
            xmiType: "logical:CoordinateSystem",
            description: this.state.description,
            angleEquation: this.state.angleEquation,
            distanceEquation: this.state.distanceEquation,
            axisRelationshipDescription: this.state.axisRelationshipDescription,
            axis,
            parent: this.state.parent,
            changeSetId: getActiveChangeSetId(),
        }

        return smmSaveNodes(node).then(res => {
          const response = res.data;
          Notifications2.parseResponse(response);

          if (response.guid) {
            NavTree.addNodes([ response ]);

            if (this.props.match.params.guid === "new") {
                return this.props.history.push( createNodeUrl(response) );
            }
            this.setStateFromResponse(response);
          }
        })
    }

    setEditingStatus = () => {
      const { subModels={}, setParentEditingStatus } = this.props;
      const { subModelId } = this.state;
      const currSubModel = subModels[subModelId];
      this.setState({ editable: !currSubModel?.created }, () => {
        setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
      });
    };

    resetCoordinateSystem = () => {
        this.inputRef.current.resetData();
        this.csaRef.current.resetData();

        if(this.props.match.params.guid === "new") {
            this.parentRef.current.resetData();
            this.state.addedCsaGuids.forEach(guid => {
                this.newCSARefs[guid].current.resetCoordinateSystemAxis();
            })
        }
    }

    addNewCSA = () => {
      const newCSAs = cloneDeep(this.state.newCSAs);

      newCSAs.push({
        key: this.newCsaCount++,
        guid: null,
        name: "",
        description: "",
        xmiType: "logical:CoordinateSystemAxis",
      })
      this.setState({ newCSAs });
      // this.forceUpdate();
    }

    removeCsa = async (idx) => {
        BasicConfirm.show("Are you sure you want to remove the Coordinate System Axis?\n(note: click SAVE to commit the change)", async () => {
          const newCSAs = [...this.state.newCSAs];
          newCSAs.splice(idx, 1);

          this.setState({ newCSAs });
        })
    }

    handleDelete = () => {
      DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    render() {
        const { guid, name, editable, deprecated } = this.state;
        const phenomId = new PhenomId("edit-coordinate-system",this.props.ctx);

        const parentTip = ["Select a Parent Package"];
        const csaTip = ["Select from existing Coordinate System Axes"].concat(
            this.props.match.params.guid === "new" ? ["Create a new Coordinate System Axis", "or do a combination of both"] : []);

        return(
            <NodeLayout guid={guid}
                        name={name}
                        editable={editable}
                        deprecated={deprecated}
                        idCtx={phenomId.genPageId()}
                        onSave={this.save}>
                  <StyledGrid>
                    <StyledInput>
                      <LineLabel text="COORDINATE SYSTEM" style={labelStyle} idCtx={phenomId.gen("details","name")} />
                      <CadetInput text={this.state.name}
                                  style={inputStyle}
                                  disabled={!this.state.editable}
                                  idCtx={phenomId.gen("input","name")}
                                  onChange={(e) => this.setState({name: e.target.value})} />

                      <LineLabel text="DESCRIPTION" style={labelStyle} idCtx={phenomId.gen("details","description-input")} />
                      <CadetTextArea text={this.state.description}
                                     style={inputStyle}
                                     disabled={!this.state.editable}
                                     idCtx={phenomId.gen("input","description-input")}
                                     onChange={(e) => this.setState({description: e.target.value})} /></StyledInput>

                    <StyledSidebar>
                      <NodeHistory2 guid={guid} idCtx={phenomId.genPageId()} />
                      {/* <ChangeSetPicker id={phenomId.genPageId()}
                                       disabled={!this.state.editable}
                                       label="Change Set" /> */}
                    </StyledSidebar>

                    <StyledContent>
                      <div style={{display:"flex"}}>
                        <div style={{width:"100%", marginRight:10}}>
                            <LineLabel text="Angle Equation" style={labelStyle} idCtx={phenomId.gen("details","angle-equation")} />
                            <CadetInput text={this.state.angleEquation}
                                        style={inputStyle}
                                        disabled={!this.state.editable}
                                        idCtx={phenomId.gen("input","angle-equation")}
                                        onChange={(e) => this.setState({angleEquation: e.target.value})} /></div>

                        <div style={{width:"100%", marginLeft:10}}>
                            <LineLabel text="Distance Equation" style={labelStyle} idCtx={phenomId.gen("details","distance-equation")} />
                            <CadetInput text={this.state.distanceEquation}
                                        style={inputStyle}
                                        disabled={!this.state.editable}
                                        idCtx={phenomId.gen("input","distance-equation")}
                                        onChange={(e) => this.setState({distanceEquation: e.target.value})} /></div>
                      </div>

                      <div>
                          <LineLabel text="Axis Relationship" style={labelStyle}/>
                          <CadetInput text={this.state.axisRelationshipDescription}
                                      style={inputStyle}
                                      disabled={!this.state.editable}
                                      idCtx={phenomId.gen("input","axis-relationship")}
                                      onChange={(e) => this.setState({axisRelationshipDescription: e.target.value})} /></div>

                      {this.props.match.params.guid === "new" &&
                      <div>
                          <LineLabel text="Parent Package" style={labelStyle} idCtx={phenomId.gen("details","parent-package")} />

                          <div style={{marginBottom:10}}>
                              <Button icon="select-box" style={{marginRight:10}} id={phenomId.gen("toggle","parent-package")} onClick={() => this.parentRef.current.toggleCollapse()}>Show/Hide selection box</Button>
                              <Button icon="info" look="bare" id={phenomId.gen("info","parent-package")} data-for="react-tip" data-tip={parentTip} /></div>

                          <SingleSelect data={this.state.parents}
                                        idCtx={phenomId.gen("select","parent-select")}
                                        selectedGuid={this.state.parent || ""}
                                        required={false}
                                        optionNoneText="No Package Selected / Use Default"
                                        collapse={this.props.match.params.guid !== "new"}
                                        onChange={(e) => this.setState({parent: e.target.value})}
                                        editable={this.state.editable}
                                        ref={this.parentRef} />

                          {this.state.parents &&
                          <StyledCardGroup columns={3}>
                              <Card node={this.state.parents[this.state.parent]} idCtx={phenomId.gen("details","parents-card")} /></StyledCardGroup>} </div>}

                        <div>
                            <LineLabel text="Coordinate System Axes" style={labelStyle} idCtx={phenomId.gen("details","axes")} />
                            <div style={{marginBottom:10}}>
                                <Button icon="select-box" style={{marginRight:10}} id={phenomId.gen("toggle","axes")} onClick={() => this.csaRef.current.toggleCollapse()}>Show/Hide selection box</Button>
                                {this.props.look !== "component" && this.props.match.params.guid === "new" &&
                                <Button icon="plus" style={{marginRight:10}} id={phenomId.gen("add","axes")} onClick={this.addNewCSA}>Add new Coordinate System Axis</Button>}
                                <Button icon="info" look="bare" data-for="react-tip" data-tip={csaTip} /></div>

                            <MultiSelect data={this.state.coordinateSystemAxes}
                                        idCtx={phenomId.gen("select","axes")}
                                        selectedGuids={this.state.csaGuids}
                                        required={true}
                                        collapse={this.props.match.params.guid !== "new"}
                                        onChange={(csaGuids) => this.setState({csaGuids})}
                                        editable={this.state.editable}
                                        ref={this.csaRef} />

                            {this.state.coordinateSystemAxes &&
                            <StyledCardGroup columns={3}>
                                {this.state.csaGuids.map(guid => {
                                    return <Card node={this.state.coordinateSystemAxes[guid]} idCtx={phenomId.gen("details","axes-card")} />
                                })}</StyledCardGroup>} </div>

                        {this.props.match.params.guid === "new" &&
                        <StyledCardGroup columns={2}>
                            {this.state.newCSAs.map((csa, idx) => {
                              return (
                                <div key={`newcsa-${csa.key}`} style={this.styles.newCSA}>
                                  <Button icon="close" look="bare" id={phenomId.gen("new","add-button")} style={this.styles.removeCSA} onClick={() => this.removeCsa(idx)} data-for="react-tip-err" data-tip="Remove Coordinate System Axis" />
                                  <CoordinateSystemAxisManager csa={csa}
                                                               idCtx={phenomId.gen(["added-cs",`${idx}`])}
                                                               isComponent={true}
                                                               ref={(el) => this.newCsaRef[idx] = el} />
                                </div>
                              )
                            })}
                        </StyledCardGroup>}

                        {this.props.match.params.guid !== "new" &&
                        <div>
                            <LineLabel text="Measurement System" style={labelStyle} idCtx={phenomId.gen(["details","measurement-system"])} />
                            <CommaLinks data={this.state.related_measurement_systems} idCtx={phenomId.gen(["measurement-system","links"])} />
                        </div>}
                    </StyledContent>
                  </StyledGrid>

                  <ReactTooltip id='react-tip-err' type="error" getContent={() => {}} />
                  <ReactTooltip id='react-tip' type="info" getContent={(str) => <div>
                      You can:
                      <ul style={{paddingLeft:20, margin:0}}>
                          {(str || "").split(",").map((txt, i) =>
                              <li key={i}> {txt} </li>)}
                      </ul></div>} />
            </NodeLayout>
        )
    }
}


export const EditCoordinateSystemManager = withPageLayout(CoordinateSystemManager, { renderResetBtn: false });
