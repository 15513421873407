import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {FormatMenuHeaderCell, PackageComboBox, PhenomInput, PhenomTextArea, PhenomLabel} from "../util/stateless";
import React from "react";
import {orderBy} from "@progress/kendo-data-query";
import {NodeHistory2} from "./node-history";
import { _ajax } from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "./node-layout";
import {createPhenomGuid} from "../util/util";
import { PhenomLink } from "../widget/PhenomLink";
import { cloneDeep } from "lodash";


export class ObservableManager extends React.Component {
    static defaultProps = {
        newNode: {
          name: "",
          xmiType: "conceptual:Observable",
          description: "",
          realizations: [],
          parent: "",
          subModelId: undefined,
        },
        nodeAddenda: {
            coreAddenda: ["realizations"],
            realizationsFilter: ["xmi:type", "logical:Measurement"],
            realizationsAddenda: ["measurementAxisMULTI", "measurementSystem"],
            measurementAxisAddenda: ["measurementSystemAxis", "valueTypeUnitMULTI"],
            valueTypeUnitAddenda: ["unit", "valueType"],
            defaultValueTypeUnitAddenda: ["unit", "valueType"],
            measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
        },
    }

    requiredFields = {
        name: {
          required: true,
          checkFirstChar: true,
          checkAllChars: true,
          errorRef: React.createRef(),
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            rlzSort: [],
        };

        this.phenomId = new PhenomId("observable",this.props.idCtx);
    }

    
    // ------------------------------------
    // Life Cycle Methods
    // ------------------------------------

    componentDidMount() {
        this.initNodeState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.node !== this.props.node) {
            this.initNodeState();
        }
    }

    componentWillUnmount() {
    }


    // ------------------------------------
    // Initial Setup
    // ------------------------------------

    initNodeState = () => {
        const { node } = this.props;

        this.setState({
            ...cloneDeep(this.defaultState),
            ...node,
        });
    }


    // ------------------------------------
    // Used By Template
    // ------------------------------------

    generateNode = () => {
        return {
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            xmiType: "conceptual:Observable",
            description: this.state.description,
            parent: this.state.parent || null,
        }
    }


    // ------------------------------------
    // Render Methods
    // ------------------------------------

    renderRealizations() {
        return (
            <div className="p-col" style={{gap: 0}}>
                <PhenomLabel text="Measurements" idCtx={this.phenomId.gen("measurements","")} />
                <Grid
                    id={this.phenomId.gen("","grid")}
                    data={orderBy(this.state.realizations, this.state.rlzSort)}
                    sortable
                    sort={this.state.rlzSort}
                    onSortChange={(e) => {
                        this.setState({
                            rlzSort: e.sort
                        });
                    }}
                    className="editorTable"
                    resizable>
                    <GridNoRecords>
                        No Data Is Available For This Table
                    </GridNoRecords>
                    <Column title="MEASUREMENTS" field="name"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={this.phenomId.gen("","measurements")}
                                useTruncation={(val) => this.setState({truncateMeas: val})} />;
                        }}
                        cell={(props) => {
                            const measurement = props.dataItem;
                            return (<td style={{wordBreak: this.state.truncateMeas ? "unset" : "break-word"}}>
                                <div className="ellipses-link"><PhenomLink node={measurement} newTab={true}/></div>
                            </td>);
                        }} />
                    <Column title="SYSTEM" field="measurementSystem.name"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title}
                                useTruncation={(val) => this.setState({truncateSystems: val})} idCtx={this.phenomId.gen("","system")} />;
                        }}
                        cell={(props) => {
                            const {measurementSystem} = props.dataItem;
                            return (
                                <td style={{wordBreak: this.state.truncateSystems ? "unset" : "break-word"}}>
                                    <div className="ellipses-link"><PhenomLink node={measurementSystem} newTab={true}/></div>
                                </td>
                            )
                        }} />
                    <Column title="AXIS"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={this.phenomId.gen("","axis")}
                                useTruncation={(val) => this.setState({truncateAxis: val})} />;
                        }}
                        cell={(props) => {
                            const {measurementAxis} = props.dataItem;
                            return (<td style={{wordBreak: this.state.truncateAxis ? "unset" : "break-word"}} id={this.phenomId.gen(["measurements","axis"],"wrapper")}>
                                <div id={this.phenomId.gen(["axes"],"div")}  style={{display: "flex", flexDirection: "column"}}>
                                    {measurementAxis.map((axis, idx) => <div className="ellipses-link"><PhenomLink node={axis} newTab={true}/></div>)}
                                </div>
                            </td>);
                        }} />
                   <Column title="LOGICAL TYPE" cell={(props) => {
                        const {measurementAxis} = props.dataItem;
                        return (
                            <td id={this.phenomId.gen(["measurements", "logical"], "wrapper")}>
                                <div id={this.phenomId.gen(["logical-type-all-axes"], "types-div")} style={{display: "flex", flexDirection: "column"}}>
                                    {measurementAxis.map((axis, idx) => {
                                        const vtus = axis.valueTypeUnit.length ? axis.valueTypeUnit : axis.measurementSystemAxis.defaultValueTypeUnit;
                                        return vtus.map((vtu, subIdx) => <div className="ellipses-link"><PhenomLink key={`${idx}-${subIdx}`} node={vtu} newTab={true}/></div>);
                                    })}
                                </div>
                            </td>
                        );
                    }} />
                    <Column title="UNIT" cell={(props) => {
                        const { measurementAxis } = props.dataItem;
                        return (
                            <td id={this.phenomId.gen(["measurements", "unit"], "wrapper")}>
                                <div id={this.phenomId.gen(["unit-all-axes"], "vtus-div")} style={{display: "flex", flexDirection: "column"}}>
                                    {measurementAxis.map((axis, idx) => {
                                        const vtus = axis.valueTypeUnit.length ? axis.valueTypeUnit : axis.measurementSystemAxis.defaultValueTypeUnit;
                                        return vtus.map((vtu, subIdx) => <div className="ellipses-link"><PhenomLink key={`${idx}-${subIdx}`} node={vtu.unit} newTab={true}/></div>);
                                    })}
                                </div>
                            </td>
                        );
                    }} />
                </Grid>
            </div>
        );
    }

    render() {
        const { editable } = this.props;
        const original = this.props.node;
        
        return (
            <div className="edit-form" >
                {/* observable fields */}
                <div className="p-row">
                    <div className="p-col">
                        <div className="p-row p-with-flex">
                            <PhenomInput label="Observable"
                                        value={this.state.name}
                                        originalValue={original["name"]}
                                        disabled={!editable}
                                        autoFocus={true}
                                        id={this.phenomId.genPageId("name")}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                        onClickResetIcon={() => this.setState({ name: original["name"] })}
                                        config={this.requiredFields["name"]} />

                            <PackageComboBox label="Parent Package"
                                                xmiType="face:ConceptualDataModel"
                                                placeholder="<Default>"
                                                nodeGuid={this.state.guid}
                                                selectedGuid={this.state.parent}
                                                originalGuid={original["parent"]}
                                                disabled={!editable}
                                                id={this.phenomId.genPageId("parent")}
                                                onChange={(parent) => this.setState({ parent: parent.guid })}
                                                onClickResetIcon={() => this.setState({ parent: original["parent"] })} />
                        </div>

                        <PhenomTextArea label="Description"
                                        value={this.state.description}
                                        originalValue={original["description"]}
                                        disabled={!editable}
                                        id={this.phenomId.genPageId("description")}
                                        onChange={(e) => this.setState({ description: e.target.value })} />
                    </div>

                    <div className="edit-side">
                        <NodeHistory2 guid={this.state.guid}
                                    ref={ele => this.historyRef = ele} />
                    </div>
                </div>

                {/* realizations */}
                <div className="p-row">
                    {this.renderRealizations()}
                </div>
            </div>
        );
    }
}

export const EditObservableManager = withPageLayout(ObservableManager);