import { primitiveTypeList } from "../global-constants";
import SkaylMetaModelFactory from "./factory/SkaylMetaModelFactory";


class NodeRuleModel {
  xmiType = "none:unknown"
  rules = {}

  constructor(xmiType, ruleset={}) {
    this.xmiType = xmiType;
    this.parseRuleset(ruleset);
  }

  parseRuleset(ruleset={}) {
    const newRules = {};

    Object.keys(ruleset).forEach((key) => {
      const data = ruleset[key];

      switch (key) {
        case "platformType":
          // currently it returns null
          if (!data.rules) {
            data.rules = {};
          }
          data.rules.references = [...primitiveTypeList];
          break;

        case "path":
          data.rules.bound = [0, -1];
          break;

        default:
      }

      let rule = SkaylMetaModelFactory.createAttributeRule(data);

      if (rule) {
        newRules[key] = rule;
      }
    });

    this.rules = newRules;
  }

  getRule(attr) {
    return this.rules[attr];
  }
}


export default NodeRuleModel;