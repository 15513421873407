import React from "react";
import { KbButton, PhenomLabel, FinalizeReviewActions, PhenomInput } from "../../util/stateless";
import ListPaneView from "../../edit/list-pane-view";
import NavTree from "../../tree/NavTree";
import { validateNodeFields, getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { PhenomLink } from "../../widget/PhenomLink";
import { finalizeMergeReview, toggleReviewNodeStatus, rejectReviewNodes, unlockProject, isProjectLocked, getRejectedReviewNodes, saveNewCommit, rebaseReview } from "../../../requests/sml-requests";
import { receiveResponse } from "../../../requests/actionCreators";
import { resetApp } from "../../../requests/actionThunks";
import { ReviewSubMenu } from "../review";
import { cloneDeep } from "lodash";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { connect } from "react-redux";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif"
import PhenomLoadButton from "../../widget/LoaderButton";
import { BasicAlert } from "../../dialog/BasicAlert";
import * as actionCreators from "../../../requests/actionCreators"
import { NoSpecialCharsRegex } from "../../../hooks/useInputError";

export function acceptReviewNode(guid, thisNodeOnly, treeCallback=false) { // wrapper function to allow resetting of data after NavTree reset
    return toggleReviewNodeStatus(guid, thisNodeOnly).then((res) => {
        receiveResponse(res);
        NavTree.reset().then(()=> treeCallback && treeCallback());

        return res;
    });
}

export function rejectReviewNode(guid, thisNodeOnly, skipValidation, commitBefore, treeCallback=false) { // wrapper function to allow resetting of data after NavTree reset
    return rejectReviewNodes(guid, thisNodeOnly, skipValidation, commitBefore).then((res) => {
        receiveResponse(res);
        if (!res.data?.errors) NavTree.reset().then(()=> treeCallback && treeCallback());

        return res;
    });
}


export class FinalizeMerge extends React.Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            commitName: null,
            guidToNodeMap: {},
            guidToActionConfigMap: {},
            loading: true,
            lists: [{ 
                collapsible: false,
                data: [],
                headerOnly: true,
                headerClass: "main",
                columns: [{header: "Review Nodes"}]
            }]
        }

        this.defaultRequiredFields = {
            ["name"]: {
              required: true,
              checkFirstChar: true,
              regexPattern: NoSpecialCharsRegex,
              errorRef: React.createRef(),
            },
        }

        this.state={...this.defaultState}
        this.requiredFields={...this.defaultRequiredFields}
    }


    // ------------------------------------------------------------
    // Life Cycle Methods
    // ------------------------------------------------------------

    componentDidMount() {
        NavTree.collapseNavTree(true);
        NavTree.reset().then(() => this.init());
    }
    
    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount(){
    }


    // ------------------------------------------------------------
    // Initial Setup
    // ------------------------------------------------------------

    init = () => {
        const typeToNodeMap = {};
        const guidToNodeMap = {};
        const guidToActionConfigMap = {...this.state.guidToActionConfigMap};

        // 1. gather underReview/accepted then append rejected nodes
        let reviewNodes = cloneDeep(NavTree.getReviewNodeDataList());
        getRejectedReviewNodes().then((res) => {
            if (res.data?.nodes && res.data.nodes.length) {
                reviewNodes.push(...res.data.nodes);
            }
            if(!reviewNodes.length) return this.setLoading(false);

            // 2. format nodes & create type map
            reviewNodes.forEach(node => {
                if (node.reviewStatus === 'Under_Review') {
                    node.reviewStatus = 'Pending'
                }

                if (typeToNodeMap[node.xmiType]) { // build temp typeToNodeMap for lists
                    typeToNodeMap[node.xmiType].push(node);
                } else {
                    typeToNodeMap[node.xmiType] = [node];
                }

                guidToNodeMap[node.guid] = node; // build guidToNodeMap, used to determine pending nodes on finalize and set initial actionType

                if (!guidToActionConfigMap[node.guid]) { // build guidToActionConfig map, stores action options and actionType
                    guidToActionConfigMap[node.guid] = {
                        actionType: "This Node Only",
                        actions: ["This Node Only"],
                    };
                }
            });

            reviewNodes.forEach(node => {
                if (guidToNodeMap[node.parent] && guidToActionConfigMap[node.parent]?.actionType === "This Node Only") {
                    guidToActionConfigMap[node.parent] = {
                        actionType: "This Node & All Children",
                        actions: ["This Node & All Children", "This Node Only"],
                    };
                }
            });

            // 3a. start list setup with initial header list
            const lists = [{ 
                collapsible: false,
                data: [...reviewNodes],
                headerOnly: true,
                headerClass: "main",
                columns: [{header: "Review Nodes"}]
            }]

            // 3b. create & sort lists for each xmiType
            Object.keys(typeToNodeMap).sort().forEach((key) => {
                const findName = (n) => n["name"] || n["rolename"] || n["xmiType"];

                typeToNodeMap[key].sort((n1, n2) => {
                  let name1 = findName(n1);
                  if (Array.isArray(n1.parents)) {
                    name1 = n1.parents.map(n => findName(n)).concat(findName(n1)).join(" ");
                  }
                  
                  let name2 = findName(n2);
                  if (Array.isArray(n2.parents)) {
                    name2 = n2.parents.map(n => findName(n)).concat(findName(n2)).join(" ");
                  }
                  
                  return name1.localeCompare(name2);
                });

                lists.push(
                    { collapsible: true,
                        collapsed: false,
                        data: typeToNodeMap[key],
                        columns: [
                            {header: getShortenedStringRepresentationOfXmiType(key), 
                            render: this.renderNodeColumn,
                            flex: 3}, 
                            {header: "Review Status",  render: this.renderReviewNodeStatus, flex: 1},
                            {header: "Actions", render: this.renderReviewNodeActions , flex: 2},
                        ]
                    }
                );
            });

            this.setState({
                lists: lists,
                loading: false,
                guidToActionConfigMap: guidToActionConfigMap,
                guidToNodeMap: guidToNodeMap,
            });
        });
    }


    // ------------------------------------------------------------
    // Setter Methods
    // ------------------------------------------------------------

    setLoading = (bool) => {
        this.setState({loading: bool});
    }

    setNodeActionType = (guid, actionType) => {
        this.setState(prevState => ({
            guidToActionConfigMap: {
                ...prevState.guidToActionConfigMap,
                [guid]: {
                    ...prevState.guidToActionConfigMap[guid],
                    actionType: actionType,
                }
            }
        }));
    }


    // ------------------------------------------------------------
    // Handler Methods
    // ------------------------------------------------------------

    handleCommit = () => {
        if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the commit name.");

        const { commitName } = this.state;
        const commitData = {
            name: commitName,
            description: "",
        }

        BasicAlert.show(`Saving '${commitName}'`, "Processing request", false);

        return saveNewCommit(commitData)
               .then((res) => {
                    receiveResponse(res);
                    // remove required fields before clearing input, since isn't being re-rendered
                    this.requiredFields = null;
                    this.setState({commitName: null}, () => this.requiredFields = {...this.defaultRequiredFields});
                })
                .always(()=> {
                    BasicAlert.hide();
                });
    }

    validateFields = () => {
        const { commitName } = this.state;
        const commitData = {
            name: commitName,
        }

        return validateNodeFields(this.requiredFields, commitData);
    }

    handleFinalizeMerge = () => {
        const { activeProjectId } = this.props;
        const afterMerge = (res) => {
            if (res.data?.errors) {
                BasicAlert.hide();
                BasicConfirm.show(
                    "The destination project has been updated during this review. To finalize the merge, a rebase of the review project is necessary.\n\nWould you like to proceed with the rebase now?",
                    ()=> {
                        BasicAlert.show("Please wait while the rebase is being performed.","Performing Rebase", false);
                        rebaseReview()
                            .then((res) => {
                                receiveResponse(res);
                                sessionStorage.clear();
                                resetApp();
                                NavTree.reset(true);
                                BasicAlert.hide();
                            })
                            .catch((err) => {
                                BasicAlert.hide();
                            });
                    },
                    ()=>{},
                    "Rebase Required"
                )
            } else {
                receiveResponse(res);
                sessionStorage.clear();
                resetApp();
                NavTree.reset(true);
                BasicAlert.hide();
            }
        }

        BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);

        isProjectLocked(activeProjectId, true)
            .then((res) => {
                const { locked_status } = res.data;
                const { guidToNodeMap } = this.state;
                const remainingPendingNodes = Object.values(guidToNodeMap).some(node => node.reviewStatus === "Pending");

                const finalizeMessage = [
                    remainingPendingNodes ? "Some nodes are still pending review; they will be accepted and merged in." : "",
                    locked_status ? "The destination Project is currently locked. To finalize the merge, it must be lifted." : "",
                    `Are you sure you want to ${locked_status ? "lift the lock and " : ""}finalize the merge? This cannot be undone.`
                ].filter(Boolean).join("\n\n");

                BasicAlert.hide();
                BasicConfirm.show(
                    finalizeMessage,
                    () => { //confirm
                        BasicAlert.show("Please wait while the merge is being finalized.","Processing Merge", false);
                        if(locked_status) {
                            unlockProject(activeProjectId, true)
                                .then(()=> {
                                    receiveResponse(res);
                                    finalizeMergeReview()
                                        .then((res) => afterMerge(res))
                                        .catch((err) => BasicAlert.hide());
                                }).catch((err) => BasicAlert.hide()); 
                        } else {
                            finalizeMergeReview()
                                .then((res) => afterMerge(res))
                                .catch((err) => BasicAlert.hide());
                        }
                    },
                    ()=> {}, // cancel
                    "Confirm Merge"
                );
            })
            .catch((err) => {
                BasicAlert.hide();
            });
    }

    // ------------------------------------------------------------
    // Render Methods
    // ------------------------------------------------------------

    renderNodeColumn = (item) => {
        return <div className="ellipses-link">
          {Array.isArray(item.parents) && item.parents.map((parent, idx) => {
            return <React.Fragment>
              <PhenomLink node={parent} newTab/><span>.</span>
            </React.Fragment>
          })}
          <PhenomLink node={item} newTab/>
        </div>
    }

    renderReviewNodeStatus = (node) => {
        return (
            <p className={node.reviewStatus}>
                {node.reviewStatus}
            </p>
        );
    }

    renderReviewNodeActions = (node) => {
        const { loading, guidToActionConfigMap } = this.state;
        
        return (
            <FinalizeReviewActions 
                node={node}
                disabled={loading}
                setLoading={this.setLoading}
                treeCallback={this.init}
                setNodeActionType={this.setNodeActionType}
                actionConfig={guidToActionConfigMap[node.guid]}
            />
        );
    }

    renderContentAboveList = () => {
        const { commitName, loading } = this.state;

        return (
            <div className="p-row">
                {/* Page Text Section */}
                <div className="p-col p-col-1" style={{gap: 0}}>
                    <PhenomLabel text="Review Changes" />
                    <p>Changes under review may be accepted or rejected from this page. Please commit before rejecting changes, as rejections are irreversible and commits allow for recovery if necessary.</p>
                </div>

                {/* Commit Section */}
                <div className="p-col p-col-1" style={{gap: 0, paddingBottom: "25px"}}>
                    <div className="p-row">
                        <PhenomLabel text="Commit" style={{width: "100%"}} />
                    </div>
                    <div className="p-row" style={{alignItems: "center"}}>
                        <PhenomInput 
                            containerProps={{style: {flex: 1}}}
                            value={commitName}
                            onChange={(e) => this.setState({commitName: e.target.value})}
                            config={this.requiredFields?.name}
                        />
                        <PhenomLoadButton 
                            style={{margin: 0}} 
                            divStyle={{height: "auto"}} 
                            text="SAVE COMMIT"
                            onClick={this.handleCommit}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { lists, loading } = this.state;
        const { reviewRequest } = this.props;

        return (
            <div className="phenom-content-wrapper"> 
                <ReviewSubMenu reviewRequest={reviewRequest}>
                    {loading &&
                        <img id={"merge-loading-icon"}
                             style={{ display: "block", height: "30px" }}  src={loadingIcon}
                        />
                    }
                    <KbButton/>
                    <button
                        className="fas fa-merge"
                        title="Finalize Merge"
                        onClick={this.handleFinalizeMerge}
                        style={{ fontSize: "1.5em", padding: 0 }}
                        disabled={loading}
                    />
                </ReviewSubMenu>

                {/* Page Content Goes In This Div */}
                <div className="phenom-content-wrapper">
                    <div style={{margin: 20, overflowY: "hidden", height: "100%"}}>
                        <ListPaneView
                            mainKey={"id"}
                            lists={lists}
                            renderContentAboveList={this.renderContentAboveList}
                            minimizePane={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const msp = (state) => ({
    reviewRequest: state.user.reviewRequest,
    activeProjectId: state.user.userIdentity.activeProjectId,
  });

export default connect(msp)(FinalizeMerge);
