import React from 'react';
import { Input, NodeComboBox, PhenomComboBox, PhenomSelect, PhenomTextArea } from "../../components/util/stateless";
import NumberRule from "../rules/NumberRule";
import StringRule from "../rules/StringRule";
import ReferenceRule from '../rules/ReferenceRule';
import TextAreaRule from '../rules/TextAreaRule';
import EnumerationRule from '../rules/EnumerationRule';
import NavTree from '../../components/tree/NavTree';
import BooleanRule from '../rules/BooleanRule';


class SkaylComponentFactory {
  static createAttributeComponent = (rule) => {

    if (rule instanceof BooleanRule) {
      return BooleanField;
    }

    if (rule instanceof StringRule) {
      return InputField;
    }

    if (rule instanceof TextAreaRule) {
      return TextAreaField;
    }

    if (rule instanceof NumberRule) {
      return NumberInputField;    
    }

    if (rule instanceof EnumerationRule) {
      return EnumerationField;
    }

    if (rule instanceof ReferenceRule) {
        return ReferenceComboxBox;
    }

    return null;
  }
}

// -------------------
// Components
// -------------------
const InputField = (props) => {
  const handleChange = (e) => {
    props.onChange && props.onChange(e.target.value);
  }

  return <Input 
            value={props.value}
            onChange={handleChange} />
}


const NumberInputField = (props) => {
  const handleChange = (e) => {
    props.onChange && props.onChange(e.target.value);
  }

  return <Input 
            value={props.value}
            type="number"
            onChange={handleChange} />
}


const TextAreaField = (props) => {
  const handleChange = (e) => {
    props.onChange && props.onChange(e.target.value);
  }

  return <PhenomTextArea 
            value={props.value}
            onChange={handleChange} />
}

const BooleanField = (props) => {
  function handleChange(e) {
    props.onChange && props.onChange(e.target.value);
  }

  const data = ["true", "false"];

  return <PhenomSelect 
            data={data}
            value={props.value}
            onChange={handleChange} />
}

const EnumerationField = (props) => {
  function handleChange(e) {
    props.onChange && props.onChange(e.target.value);
  }

  return <PhenomSelect 
            data={props.enumList || []}
            onChange={handleChange} />
}

const ReferenceComboxBox = (props) => {
  const nodeData = NavTree.getNodeData(props.value);
  const listTypes = NavTree.getNodeDataList(props.xmiTypes);

  function handleChange(node) {
    if (!props.onChange) {
      return;
    }

    if (!node?.guid) {
      props.onChange(null);
    }

    props.onChange(node.guid);
  }

  return <PhenomComboBox
            value={nodeData}
            data={listTypes}
            dataItemKey="guid"
            showXmiType
            onChange={handleChange} />
}


export default SkaylComponentFactory;