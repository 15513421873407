import NodeRuleModel from "./NodeRuleModel";

class SkaylMetaModel {
  smm = {}

  addRuleset(xmiType, ruleset={}) {
    if (!xmiType || !ruleset) return;
    this.smm[xmiType] = new NodeRuleModel(xmiType, ruleset);
  }

  getRuleset(xmiType) {
    return this.smm[xmiType];
  }
}


export default SkaylMetaModel;