import AttributeRuleModel from "./AttributeRuleModel";


class BooleanRule extends AttributeRuleModel {

  constructor(ruleset={}) {
    super();

    const { flags=[], rules={} } = ruleset;
    this.parseFlags(flags);
    this.parseRules(rules);
  }

  getDefaultValue() {
    const bool = super.getDefaultValue();

    if (typeof bool === "boolean") {
      return bool;
    } else if (typeof bool === "string") {
      return bool === "true";
    }

    return false;
  }
}


export default BooleanRule;