import React from "react";
import NavTree from "../../../tree/NavTree";
import { Checkbox } from "@progress/kendo-react-inputs";
import { resetApp } from "../../../../requests/actionThunks";
import ReactTooltip from "react-tooltip";
import { createReviewProject, getMergeRequestReview, switchProject, isProjectLocked } from "../../../../requests/sml-requests";
import LoaderButton from "../../../widget/LoaderButton"
import { PhenomInput, PhenomTextArea } from "../../../util/stateless";
import * as actionCreators from "../../../../requests/actionCreators"
import { BasicAlert } from "../../../dialog/BasicAlert";
import { cloneDeep } from "lodash";
import { validateNodeFields } from "../../../util/util";

export class RequestReviewDetails extends React.Component {
    constructor(props) {
      super(props);
  
      this.newReviewProject = {
        name: "",
        description: "",
        lockDstModel: true,
        requestId: null,
      }
  
      this.defaultState = {
        loading: true,
        isCreateReviewProject: false,
        lockable: false,
        reviewProject: {...this.newReviewProject},
        reviewProjectExists: false,
        deprecateDeletes: false,
        deprecateMoves: false,
      }
  
      this.state = cloneDeep(this.defaultState);
  
      this.requiredFields = {
        ["name"]: {
          required: true,
          checkFirstChar: true,
          checkAllChars: true,
          errorRef: React.createRef(),
        },
      }
    }
  
    componentDidMount() {
      this.setLockable();
      this.getReviewProject();
      ReactTooltip.rebuild();
    }
  
    componentDidUpdate(prevProps, prevState) {
      const {requestId} = this.props;
  
      if (prevProps.requestId !== requestId) {
        this.resetOptions();
        this.getReviewProject();
      }
    }
  
    resetOptions = () => {
      this.setState({
        isCreateReviewProject: false,
        deprecateDeletes: false,
        deprecateMoves: false,
      })
    }

    toggleDeleteDeprecation = () => {
      this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
    }

    toggleMoveDeprecation = () => {
      this.setState({ deprecateMoves: !this.state.deprecateMoves })
    }
  
    getReviewProject = () => {
      const { type, hideSummary } = this.props;
      this.setState({loading: true});
      if (type === "approve") {
        const { requestId } = this.props;
  
        getMergeRequestReview(requestId).then((res) => {
          const existingReview = !!res.data;

          this.setState(prevState => ({
            reviewProjectExists: existingReview,
            reviewProject: existingReview ? res.data : prevState.reviewProject,
            loading: false,
          }));
          hideSummary && hideSummary(existingReview);
        }).always(()=> BasicAlert.hide());
      } else if (type === "pull") {
        getMergeRequestReview().then((res) => {
          const existingReview = !!res.data;

          this.setState(prevState => ({
            reviewProjectExists: existingReview,
            reviewProject: existingReview ? res.data : prevState.reviewProject,
            loading: false,
          }));
          hideSummary && hideSummary(existingReview);
        });
      }
    }
  
    updateReviewProject = (key, value) => {
      this.setState(prevState => ({
          reviewProject: {
              ...prevState.reviewProject,
              [key]: value
          }
      }));
    }
  
    setLockable = () => {
      const {userIdentity, skaylAdmin} = this.props;
      if(!skaylAdmin) return;
  
      return isProjectLocked(userIdentity.branchId).then(res => {
        const disableLock = res.data?.locked_status;
  
        this.setState({lockable: !disableLock});
      });
    }
  
    validateFields = () => {
      const { reviewProject, isCreateReviewProject } = this.state;
      if(!isCreateReviewProject) return true;
  
      return validateNodeFields(this.requiredFields, reviewProject);
    }
  
    handleSelectAll = () => {
      NavTree.selectAllMergeCandidates();
    };
  
    handleReviewProjectSwitch = () => {
      const { reviewProject } = this.state;
  
      switchProject(reviewProject.reviewProjectId).then(() => {
        sessionStorage.clear();
        NavTree.reset(true);
        resetApp().then(() => {
          this.props.history.push('/manage/review/finalize-merge');
        });
      });
    }
  
    handleReviewProjectDetails = () => {
      const { reviewProject } = this.state;
  
      this.props.history.push({
        pathname: '/manage/branch/model-manage',
        state: { selectProjectId: reviewProject.reviewProjectId }
      });
    }
  
    handleCreateReviewProject = () => {
      if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the missing fields.");
      const { requestId, loading, hideSummary } = this.props;
      const { reviewProject } = this.state;
  
      const requestMap = NavTree.getRequestMap();
      if (!requestMap) return actionCreators.receiveErrors("Please make a selection and try again.");
  
      this.setState({loading: true});
  
      const reqReviewProject = {
        ...reviewProject,
        requestId: requestId || undefined,
        merges: requestMap,
      }
  
      return createReviewProject(reqReviewProject)
        .then((res)=> {
          hideSummary && hideSummary(true);
          actionCreators.receiveResponse(res);
          this.setState({
            isCreateReviewProject: false,
            reviewProjectExists: true,
            reviewProject: {
              ...reviewProject,
              reviewProjectId: res.data.reviewModelId,
            }
          }, ()=> this.setState({loading: false}));
          this.setLockable();
      }).catch((err) => {
        this.setState({loading: false});
      });
    }
  
    renderCreateReqOrReview() {
      const { skaylAdmin, handleCreateRequest, type, loading } = this.props;
      const { reviewProject, isCreateReviewProject, lockable, deprecateDeletes, deprecateMoves } = this.state;
      const cinc_works = this.props.userRole.indexOf('c') !== -1;

      return (
        <div className="p-col p-col-1">
          <div className="p-col" style={{ gap: 7, marginBottom: "5px", color: "#565656" }}>
            <div className="p-row">
                <Checkbox label="Pull Deletions as Deprecations" checked={this.state.deprecateDeletes} onChange={this.toggleDeleteDeprecation} className="kendo-to-phenom-checkbox"/>
            </div>
            <div className="p-row">
              <Checkbox label="Pull Node Moves as Deprecations" checked={this.state.deprecateMoves} onChange={this.toggleMoveDeprecation} className="kendo-to-phenom-checkbox"/>
            </div>
            {skaylAdmin && (
              <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                <Checkbox label="New Review Project" checked={isCreateReviewProject} onChange={(e) => this.setState({ isCreateReviewProject: e.value })} className="kendo-to-phenom-checkbox"/>
                <div style={{ display: "flex" }}>
                  <span className="fas fa-info-circle"
                            style={{margin: "0px 0px 2px 5px"}}
                            data-tip={`
                              A review project is a temporary project allowing you to review the impacts of the merge before finalizing it.
                              <br/><br/>
                              Like any other projects, you can access it from the "Manage Projects" page or from the merge request it originated from.
                              <br/><br/>
                              When creating a review project, you have the ability to lock your current project (i.e., the project you are merging into).
                              This will prevent users from making any changes to it for the duration of the review. The project will be unlocked when you finalize the merge. 
                              <br/>
                              Note that it is possible to have multiple review projects (from different merge requests) based on the same project.
                              Your project may be locked but it doesnt restrict the creation of additional review projects.`}
                            data-for="hoverTip"
                            data-html={true}
                            data-place="right"/>
                  <ReactTooltip id='hoverTip'/>
                </div>
              </div>
            )}
  
            {isCreateReviewProject && (
              <div style={{ marginLeft: "20px", gap: 5 }}>
                <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                  <Checkbox label="Lock Current Project During Review" checked={!lockable || reviewProject?.lockDstModel} onChange={(e) => this.updateReviewProject("lockDstModel", e.value)} disabled={!lockable} className="kendo-to-phenom-checkbox"/>
                  <span className="fas fa-info-circle"
                            style={{margin: "0px 0px 2px 5px"}}
                            data-tip={lockable ? `
                              Locking a project during the review process is strongly recommended to prevent any modifications to the merge destination.
                              This ensures that the destination project remains unchanged throughout the review. 
                              <br/><br/>
                              If changes are made while the destination project is under review, a content rebase will be necessary before finalizing the merge.
                              <br/><br/>
                              Once a project is locked, it cannot be locked again until the review is completed.
                              `
                              :
                              `The current project is already locked.`}
                            data-for="lock-info"
                            data-html={true}
                            data-place="right"/>
                  <ReactTooltip id='lock-info' className="tool-tip"/>
                </div>
                <div className="p-col">
                  <PhenomInput
                    label={"New Review Project"}
                    containerProps={{ style: { marginTop: "5px" } }}
                    autoFocus={true}
                    value={reviewProject?.name}
                    onChange={(e) => this.updateReviewProject("name", e.target.value)}
                    config={this.requiredFields["name"]}
                  />
                  <PhenomTextArea
                    label="Description"
                    className
                    containerProps={{ style: { marginBottom: "10px" } }}
                    style={{ minHeight: "50px" }}
                    value={reviewProject?.description}
                    onChange={(e) => this.updateReviewProject("description", e.target.value)}
                  />
                </div>
              </div>
            )}
  
            <div className="p-row" style={{ gap: 0 }}>
              <LoaderButton onClick={this.handleSelectAll} disabled={cinc_works} text="SELECT ALL" style={{ marginRight: "5px" }} />
              <LoaderButton
                text={isCreateReviewProject ? "CREATE REVIEW PROJECT" : `${type === "approve" ? "APPROVE" : "PULL"} CHANGES`}
                onClick={() => isCreateReviewProject ? this.handleCreateReviewProject() : handleCreateRequest && handleCreateRequest(deprecateDeletes, deprecateMoves)}
                disabled={loading || this.state.loading || cinc_works}
              />
            </div>
        </div>
      </div>
      );
    }
  
    renderExistingReviewProject() {
      const { loading } = this.props;
      const { reviewProject } = this.state;
  
      return(
        <div className="p-col p-col-1" style={{gap: "7px", paddingBottom: "1px"}}>
          <div className="p-row" >
            <PhenomInput 
              label={"Review Project"}
              autoFocus={true}
              disabled={true}
              value={reviewProject.name}
              onChange={(e) => this.updateReviewProject("name", e.target.value)} 
              config={this.requiredFields["name"]}
              containerProps={{style: {flex: 1}}}
            />
          </div>
          <p>Navigate to the Manage Projects page to view more details and change the Project permissions.</p>
          <div className="p-row" style={{ gap: 0 }}> 
            <LoaderButton 
              onClick={this.handleReviewProjectSwitch} 
              disabled={loading || this.state.loading }
              text="SWITCH TO PROJECT" 
              style={{ marginRight: "5px" }} 
            />
            <div>
              <LoaderButton
                text={"PROJECT DETAILS"}
                onClick={this.handleReviewProjectDetails}
                disabled={loading || this.state.loading }
              />
            </div>
          </div>
        </div>
      );
    }
  
    render() {
      const { reviewProjectExists, loading } = this.state;
  
      return (
        <>  
          {reviewProjectExists ? 
              this.renderExistingReviewProject() : 
              this.renderCreateReqOrReview()}
        </>
      );
    }
  }
