import React, {Component} from "react";
import { KbButton, ResizeBarHorizontal, ResizeBarVertical } from "../../util/stateless";
import $ from "jquery";
import { connect } from "react-redux";
import { ModelLeaf, BranchLeaf, ProjectLeaf } from "../../tree/leaf/ModelLeaf";
import NavTree from "../../tree/NavTree";

import { SubMenuLeft, SubMenuRight } from "../../edit/edit-top-buttons";
import { NavLink } from "react-router-dom";
import ModelTree from "./sections/ModelTree";
import { ManageDetail } from "./sections/ManageDetail";
import * as actionCreators from "../../../requests/actionCreators";
import { ContextMenu, createPhenomGuid, isPhenomGuid } from "../../util/util";
import { BasicConfirm } from "../../dialog/BasicConfirm";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import { BasicAlert } from "../../dialog/BasicAlert";
import { getCurrentModelRefIndex, resetApp } from "../../../requests/actionThunks";
import { fetchProjectsAndModels, switchProject } from "../../../requests/sml-requests";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";
import { _ajax } from "../../../requests/sml-requests";



export const createLeafDomId = (leaf) => {
  
  if (leaf instanceof BranchLeaf) {
    return `branch-leaf-${leaf.getName()}`;
  }

  if (leaf instanceof ProjectLeaf) {
    return `project-leaf-${leaf.getName()}`;
  }

  if (leaf instanceof ModelLeaf) {
    return `model-leaf-${leaf.getName()}`;
  }

  return "";
}


export const MANAGE_OPTIONS = Object.freeze({
  newPublishModel: 1,
  newPublishProject: 2,
  newFilteredProject: 3,
  newCopyProject: 4,
})



class ModelManager extends Component {
    detailRef = React.createRef();
    projectTreeRef = React.createRef();
    branchTreeRef = React.createRef();
    prevStyleProjTree = "";

    constructor(props) {
        super(props);

        this.defaultState = {
          manage_option: null,
          createPublishModel: false,
          createPublishProject: false,
          createFilteredProject: false,
        }

        this.state = {
            isLoading: false,
            deletableLeaf: null,
            selectedLeaf: null,
            projectIndex: {},  
            branchIndex: {},
            modelIndex: {},   // sub-models/branch-reference

            collapseProjectTree: false,
            collapseModelTree: false,
            
            isFilteredProjectAlterable: false,
            ...this.defaultState,
        };

        this.existingModels = {};
        this.originalTree = {};
        this.modelTree = undefined;
        this.noticeRef = undefined;
    }


    componentDidMount() {
      NavTree.collapseNavTree(true);
      const { username, activeProjectId } = this.props.userIdentity;

      this.setState({ isLoading: true });

      fetchProjectsAndModels(username, this.props.location.state?.selectProjectId ? null : activeProjectId).then((data => {
        this.setState({ branchIndex: data.branchIndex, modelIndex: data.modelIndex, projectIndex: data.projectIndex, isLoading: false });
      }))

      // isCreateProject && isCreateModel is attached from NavigateWidget
      if (this.props.location.isCreateProject) {
        this.createNewProject();
      } else if (this.props.location.isCreateModel) {
        this.createNewModel();
      }
    }

    componentDidUpdate(prevProps, prevState) {
      // turn these settings off when changing leaf node
      if (prevState.selectedLeaf !== this.state.selectedLeaf) {
        ContextMenu.__singleton.setState({
          selectedLeaf: this.state.selectedLeaf,
          prevSelectedLeaf: prevState.selectedLeaf
        })
        this.setState({ 
          ...this.defaultState,
          isFilteredProjectAlterable: false,
        }, () => {
          // if (this.state.selectedLeaf instanceof ProjectLeaf && !isPhenomGuid(this.state.selectedLeaf.getId())) {
          //   this.fetchIsProjectFilterable();
          // }
        });
      }
    }

    componentWillUnmount(){
      ContextMenu.__singleton.setState({
        selectedLeaf: null,
        prevSelectedLeaf: null
      })
    }

    isActiveProject = () => {
      const { selectedLeaf } = this.state;
      const { activeProjectId } = this.props.userIdentity;
      let isActive = false;

      if (selectedLeaf instanceof ProjectLeaf) {
        isActive = activeProjectId === selectedLeaf.getId() && !selectedLeaf.isNewLeaf();
      }
      
      return isActive;
    }
    
    isActiveModel = () =>{
      const { selectedLeaf } = this.state;
      const { activeProjectId } = this.props.userIdentity;
      const projectLeaf = selectedLeaf.getProjectLeaf();
      let isActive = false;
      
      if (projectLeaf !== null && selectedLeaf instanceof ModelLeaf){
        isActive = activeProjectId === projectLeaf.getId() && !selectedLeaf.isNewLeaf();
      }

      return isActive;
    }

    fetchIsProjectFilterable = () => {
      const projectLeaf = this.state.selectedLeaf;
      _ajax({
        url: "/index.php?r=/referencing-model/model-filterable",
        method: "POST",
        data: {
          modelId: projectLeaf.getId(),
        }
      }).then((response) => {
        this.setState({ isFilteredProjectAlterable: (response.status == "success") });
      })
    }

    refreshTrees = async (selectModelId=null, selectProjectId=null) => {
      BasicAlert.show("Refreshing Project/Model trees", "One Moment", false);
      const { username, activeProjectId } = this.props.userIdentity;

      // refresh App's submodel data - used to be handled by ModelPicker
      getCurrentModelRefIndex();

      // 0) Save Leaf state
      // ------------------
      const projectConfigs = [];
      for (let id in this.state.projectIndex) {
        const projectLeaf = this.state.projectIndex[id];
        if (projectLeaf.isExpanded()) projectConfigs.push( projectLeaf.serializeConfig() );
      }

      const branchConfigs = [];
      for (let id in this.state.branchIndex) {
        const branchLeaf = this.state.branchIndex[id];
        if (branchLeaf.isExpanded()) branchConfigs.push( branchLeaf.serializeConfig() );
      }

      const modelConfigs = [];
      for (let id in this.state.modelIndex) {
        const modelLeaf = this.state.modelIndex[id];
        if (modelLeaf.isExpanded()) modelConfigs.push( modelLeaf.serializeConfig() );
      }

      // 1) Refresh/Recreate trees
      // -------------------------
      const data = await fetchProjectsAndModels(username, activeProjectId);
      this.setState({ branchIndex: data.branchIndex, modelIndex: data.modelIndex, projectIndex: data.projectIndex }, () => {
        
        // 2) Restore Leaf States
        // -------------------------
        for (let item of projectConfigs) {
          const projectLeaf = this.state.projectIndex[item.id];
          projectLeaf && projectLeaf.setConfig(item.config);
        }
  
        for (let item of branchConfigs) {
          const branchLeaf = this.state.branchIndex[item.id];
          branchLeaf && branchLeaf.setConfig(item.config);
        }
  
        for (let item of modelConfigs) {
          const modelLeaf = this.state.modelIndex[item.id];
          modelLeaf && modelLeaf.setConfig(item.config);
        }

        // 3) Reestablish reference pointer (trees were recreated in step 1)
        // -------------------------
        let newSelectedLeaf;
        // Model was created/edited, select it after tree refresh
        if (selectModelId) {
          newSelectedLeaf = this.state.modelIndex[selectModelId];
  
        // Project was created/edited, select it after tree refresh
        } else if (selectProjectId) {
          newSelectedLeaf = this.state.projectIndex[selectProjectId];
        }

        // 3A) A new model/project/branch was created, reestablish the ref pointer
        // -------------------------
        newSelectedLeaf && newSelectedLeaf.setExpanded(true);
        this.selectLeaf(newSelectedLeaf, () => {
          if (selectProjectId) {
            this.projectTreeRef.current.scrollToLeaf(newSelectedLeaf);
          } else {
            this.branchTreeRef.current.scrollToLeaf(newSelectedLeaf);
          }
        })

        // need to reset previous query in case user searches for same node again after tree refresh
        this.projectTreeRef.current.resetPrevSearch();
        this.branchTreeRef.current.resetPrevSearch();

        BasicAlert.hide();
      });
    }

    reloadPage = () => {
      const resetTreeFilter = true;
      
      resetApp();
      NavTree.reset(resetTreeFilter);
    }

    handleSave = () => {
      if (!this.detailRef.current) return;
      this.detailRef.current.save();
    }

    handleSwitch = (switchLeaf) => {
      if (!switchLeaf) return;

      this.setState({isLoading: true});

      switchProject(switchLeaf.getId()).then(response => {
        if (response.status === "success") {
          sessionStorage.clear();
          this.setState({isLoading: false});
          this.reloadPage();
        }
      }).catch(err => {
        this.setState({isLoading: false});
      })
    };

    createNewModel = () => {
      if (this.state.selectedLeaf) {
        this.state.selectedLeaf.setSelected(false);
      }

      this.setState({ 
        ...this.defaultState,
        selectedLeaf: new ModelLeaf({
          id: createPhenomGuid(),
          name: "",
        }), 
      });
    }

    createNewProject = () => {
      if (this.state.selectedLeaf) {
        this.state.selectedLeaf.setSelected(false);
      }

      this.setState({ 
        ...this.defaultState,
        selectedLeaf: new ProjectLeaf({
          id: createPhenomGuid(),
          name: "",
        }), 
      });
    }

    onCreateFilteredProject = () => {
      if (this.state.selectedLeaf instanceof ProjectLeaf === false) {
        return;
      }

      this.setState({ createFilteredProject: "new" });
    }

    onEditFilteredProject = () => {
      if (this.state.selectedLeaf instanceof ProjectLeaf === false) {
        return;
      }

      this.setState({ createFilteredProject: "edit" });
    }

    onCopyProject = () => {
      if (this.state.selectedLeaf instanceof ProjectLeaf === false) {
        return;
      }

      this.setState({ manage_option: MANAGE_OPTIONS.newCopyProject });
    }


    // ==========================================================================================================
    // SELECT LEAF
    // ==========================================================================================================
    selectLeaf = (newSelectedLeaf=null, callback) => {

      // this is redundant
      this.state.selectedLeaf && this.state.selectedLeaf.setSelected(false);
      newSelectedLeaf && newSelectedLeaf.setSelected(true);

      this.setState({ ...this.defaultState, selectedLeaf: newSelectedLeaf }, 
        callback);
    }
    
    clearSelectedLeaf = () => {
      this.setState({ 
        ...this.defaultState,
        selectedLeaf: null, 
      });
    }

    handleAutoSelectLeaf = () => {
      if (!this.props.location.state?.selectProjectId) return;
      const leaf = this.state.projectIndex[this.props.location.state?.selectProjectId];
      if (!leaf) return;
      
      leaf.setExpanded(true);
      this.selectLeaf(leaf, () => {
        this.projectTreeRef.current.scrollToLeaf(leaf);

        this.props.history.replace({
          pathname: '/manage/branch/model-manage',
          state: {} 
        })
      });
    }


    // ==========================================================================================================
    // DELETE LEAF
    // ==========================================================================================================
    onDeleteCallback = async (projectIds=[], modelIds=[]) => {
      BasicAlert.show("Refreshing Tree. One moment please.", "Refreshing", false);
      await this.refreshTrees();

      const { selectedLeaf } = this.state;
      if (selectedLeaf instanceof ProjectLeaf && projectIds.includes(selectedLeaf.getId())) {
        this.clearSelectedLeaf();
      }

      if (selectedLeaf instanceof ModelLeaf && modelIds.includes(selectedLeaf.getId())) {
        this.clearSelectedLeaf();
      }
      BasicAlert.hide();
    }

    showDeleteDialog = (removeLeaf) => {
      this.setState({ deletableLeaf: removeLeaf });
    }

    handleReset = () => {
      const { selectedLeaf } = this.state;
      this.setState({ selectedLeaf: null},
        () => this.setState({ selectedLeaf }));
    }

    handleContextMenu = (event, leaf) => {
      event.preventDefault();
      if (!leaf) return;
      const { activeProjectId } = this.props.userIdentity;
      const projectRightClickedLeaf = leaf.getProjectLeaf();
      let projectIdRightClickedLeaf = null;

      if(projectRightClickedLeaf instanceof ProjectLeaf){
        projectIdRightClickedLeaf = projectRightClickedLeaf.getId();
      }

      const menuItems = [];
      if (leaf instanceof ProjectLeaf) {
        if (leaf.getId() !== activeProjectId) {
          menuItems.push({
            text: "Switch",
            func: () => {
              this.handleSwitch(leaf);
            }
          })
        }

        if (!leaf.isPublished() && !leaf.getProjectUnderReview()) {
          menuItems.push({
            text: "Publish",
            func: () => {
              this.selectLeaf(leaf, () => {
                this.setState({ createPublishProject: true });
              });
            }
          })
        }

        if(!leaf.getProjectUnderReview()) {
          menuItems.push({
            text: "Copy",
            func: () => {
              this.selectLeaf(leaf, () => {
                this.setState({ manage_option: MANAGE_OPTIONS.newCopyProject });
              })
            }
          })
        }

        if (leaf.getId() !== activeProjectId) {
          menuItems.push({
            text: "Delete",
            func: () => {
             this.showDeleteDialog(leaf);
           }
         })
        }
        
      } else if (leaf instanceof ModelLeaf) {
        if (!leaf.isPublished() && (!leaf.getProjectLeaf() || !leaf.getProjectLeaf().getProjectUnderReview())) {
          menuItems.push({
            text: "Publish",
            func: () => {
              this.selectLeaf(leaf, () => {
                this.setState({ createPublishModel: true });
              });
            }
          })
        }
        if (projectIdRightClickedLeaf !== activeProjectId || projectIdRightClickedLeaf === null)  {
          menuItems.push({
            text: "Delete",
            func: () => {
              this.showDeleteDialog(leaf);
            }
          })
        }
      }
      if (menuItems.length) {
        ContextMenu.show(menuItems, { left: event.pageX + 5, top: event.pageY });
      }
    }

    handleVerticalResize = (dY) => {
      const container = document.querySelector("#manage-files .manage-files-wrapper");
      const projects = document.querySelector("#project-files");
      const containerRect = container.getBoundingClientRect();
      const projectRect = projects.getBoundingClientRect();
      const headerRect = projects.querySelector("header").getBoundingClientRect();
      const offsetHeight = 33; // height of resize bar (13) + height of gap (10 * 2)

      let newHeight = projectRect.height + dY;
      // minimum height -> prevent the first tree from being completely hidden
      newHeight = Math.max(newHeight, headerRect.height);
      // maximum height -> prevent the second tree from being completely hidden
      newHeight = Math.min(newHeight, containerRect.height - headerRect.height - offsetHeight);

      container.style.gridTemplateRows = `${newHeight}px 13px 1fr`;
    }

    handleCollapseProjectTree = (isExpanded) => {
      const container = document.querySelector("#manage-files .manage-files-wrapper");

      if (isExpanded) {
        container.style.gridTemplateRows = this.prevStyleProjTree;
        this.prevStyleProjTree = "";
      } else {
        this.prevStyleProjTree = container.style.gridTemplateRows;
        container.style.gridTemplateRows = "auto 13px 1fr";
      }
    }

    handleHorizontalResize = (dMouse) => {
      const container = document.querySelector("#manage-files");
      const rect = container.getBoundingClientRect();
      const newWidth = Math.max(rect.width - dMouse, 230);
      container.style.width = newWidth + "px";
      // sessionStorage.setItem("navTreeSize", newWidth);
    }


    // ==========================================================================================================
    // RENDER
    // ==========================================================================================================
    renderPublishModelButton() {
      const { selectedLeaf, isLoading, manage_option } = this.state;
      const modelProject = selectedLeaf.getProjectLeaf();
      const isInProject = !!modelProject;
      const isInReviewProject = isInProject && modelProject.getProjectUnderReview();
      const isPublishable = selectedLeaf instanceof ModelLeaf && !selectedLeaf.isPublished() && !selectedLeaf.isNewLeaf() && !this.state.createPublishModel && (!isInProject || !isInReviewProject);

      if (!isPublishable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-publish-model"
                      className="fas fa-upload"
                      title="Publish Model"
                      onClick={() => this.setState({ createPublishModel: true })}
                      disabled={isLoading} />
    }

    renderPublishProjectButton() {
      const { selectedLeaf, isLoading, manage_option } = this.state;
      const isPublishable = selectedLeaf instanceof ProjectLeaf && !selectedLeaf.isPublished() && !selectedLeaf.isNewLeaf() && !this.state.createPublishProject && !selectedLeaf.getProjectUnderReview();

      if (!isPublishable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-publish-project"
                      className="fas fa-upload"
                      title="Publish Project"
                      onClick={() => this.setState({ createPublishProject: true })}
                      disabled={isLoading} />
    }


    renderSwitchButton() {
      const { selectedLeaf, createPublishProject, isLoading, manage_option } = this.state;
      const { activeProjectId } = this.props.userIdentity;
      const isSwitchable = selectedLeaf instanceof ProjectLeaf && activeProjectId !== selectedLeaf.getId() && !selectedLeaf.isNewLeaf() && !createPublishProject;

      if (!isSwitchable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-switch"
                      className="fas fa-random"
                      title="Switch"
                      onClick={() => this.handleSwitch(selectedLeaf)}
                      disabled={isLoading} />
    }

    renderSaveButton() {
      const { isLoading } = this.state;

      return <button id="form-action-save"
                      className="fas fa-save"
                      title="Save"
                      onClick={this.handleSave} 
                      disabled={isLoading} />
    }

    renderResetButton() {
      const { selectedLeaf, createPublishModel, createPublishProject, createFilteredProject, isLoading, manage_option } = this.state;
      const isResetable = selectedLeaf && !createPublishModel && !createPublishProject && !createFilteredProject;

      if (!isResetable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-reset"
                      className="fas fa-undo"
                      title="Reset"
                      onClick={this.handleReset}
                      disabled={isLoading} />
    }

    renderDeleteButton() {
      const { selectedLeaf, createPublishModel, createPublishProject, createFilteredProject, isLoading, manage_option } = this.state;
      const isDeletable = selectedLeaf && !createPublishModel && !createPublishProject && !createFilteredProject && !selectedLeaf.isNewLeaf() && !this.isActiveProject() &&  !this.isActiveModel();

      if (!isDeletable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-delete"
                      className="fas fa-trash"
                      title="Delete"
                      onClick={() => this.showDeleteDialog(selectedLeaf)} 
                      disabled={isLoading} />
    }

    renderEditFilterProjectButton() {
      const { selectedLeaf, isFilteredProjectAlterable, isLoading, manage_option } = this.state;
      const isProjectEditable = selectedLeaf instanceof ProjectLeaf && isFilteredProjectAlterable && !selectedLeaf.isNewLeaf();

      if (!isProjectEditable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-edit-project-filter"
                      className="img-icon icon-edit-filtered-project"
                      title="Edit Filtered Project"
                      style={{ width: 17 }}
                      onClick={this.onEditFilteredProject}
                      disabled={isLoading} />
    }

    renderLoadingWheel() {
      const { isLoading } = this.state;

      if (!isLoading) return;

      return <img id={"manage-loading-icon"}
                    style={{ display: "block", height: "30px" }}  src={loadingIcon}/>
    }

    renderCopyProjectButton() {
      const { selectedLeaf, createPublishProject, isLoading, manage_option } = this.state;
      const isProjectCopyable = selectedLeaf instanceof ProjectLeaf && !selectedLeaf.isNewLeaf() && !createPublishProject && !selectedLeaf.getProjectUnderReview();

      if (!isProjectCopyable) return null;

      switch (manage_option) {
        case MANAGE_OPTIONS.newCopyProject:
          return null;

        default:
      }

      return <button id="form-action-copy"
                      className="fas fa-copy"
                      title="Copy Project"
                      onClick={this.onCopyProject} 
                      disabled={isLoading} />
    }

    render() {
        const { selectedLeaf, deletableLeaf, isLoading } = this.state;
        const { isReviewProject } = this.props;
        const { activeProjectId } = this.props.userIdentity;
        const userIdentity = this.props.userIdentity;
        const isProjectFilterable = selectedLeaf instanceof ProjectLeaf && !selectedLeaf.isNewLeaf();
        
        return <div className="phenom-content-wrapper phenom-content-scrollable">
                <ManageSubMenu isReviewProject={isReviewProject}>
                    { this.renderLoadingWheel() }
                    <KbButton />
                  {selectedLeaf && <>
                    { this.renderSwitchButton() }
                    { this.renderPublishModelButton() }
                    { this.renderPublishProjectButton()}
                    { this.renderCopyProjectButton() }
                    {/* { this.renderEditFilterProjectButton() } */}
                    { this.renderSaveButton() }
                    { this.renderResetButton() }
                    { this.renderDeleteButton() }
                  </> }
                </ManageSubMenu>

                {deletableLeaf &&
                  <DeleteModelLeaf removeLeaf={deletableLeaf}
                                   onDeleteCallback={this.onDeleteCallback}
                                   hide={() => this.setState({ deletableLeaf: null })} /> }

                <div className="manage-detail-container">
                  <div id="manage-files">
                    <div className="manage-files-wrapper">
                      <ModelTree id="project-files"
                                 treeType="projects"    // used for highlighting leaves
                                 title="Project"        // header text
                                 loading={isLoading}
                                 index={this.state.projectIndex}
                                 activeProjectId={activeProjectId}
                                 onSelect={this.selectLeaf}
                                 onSearch={() => this.forceUpdate()}
                                 onCollapse={this.handleCollapseProjectTree}
                                 onAddNew={this.createNewProject}
                                 onCreateFilteredProject={isProjectFilterable && this.onCreateFilteredProject}
                                 onContextMenu={this.handleContextMenu}
                                 initCallback={this.handleAutoSelectLeaf}
                                 ref={this.projectTreeRef} />
                      <ResizeBarVertical id="manage-files-vert-resize"
                                         style={{ alignSelf: "center" }}
                                         onResize={this.handleVerticalResize} />

                      <ModelTree id="model-files"
                                 treeType="models"      // used for highlighting leaves
                                 title="Model"          // header text
                                 loading={isLoading}
                                 index={this.state.branchIndex}
                                 onSelect={this.selectLeaf}
                                 onSearch={() => this.forceUpdate()}
                                 onAddNew={this.createNewModel}
                                 onContextMenu={this.handleContextMenu}
                                 ref={this.branchTreeRef} />
                    </div>
                    <ResizeBarHorizontal onResize={this.handleHorizontalResize} />
                  </div>

                  <div id="manage-single-file">
                      <ManageDetail leaf={selectedLeaf}
                                    user={userIdentity}
                                    activeProjectId={activeProjectId}
                                    manage_option={this.state.manage_option}
                                    createPublishModel={this.state.createPublishModel}
                                    createPublishProject={this.state.createPublishProject}
                                    createFilteredProject={this.state.createFilteredProject}
                                    refreshTrees={this.refreshTrees}
                                    clearSelectedLeaf={this.clearSelectedLeaf}
                                    projectIndex={this.state.projectIndex}
                                    branchIndex={this.state.branchIndex}
                                    modelIndex={this.state.modelIndex}
                                    ref={this.detailRef} />
                  </div>
                </div>
        </div>
    }
}





class DeleteModelLeaf extends React.Component {
  state = {
    subModels: [],
    selectedIds: [],
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const { removeLeaf } = this.props;

    if (removeLeaf instanceof ProjectLeaf) {
      _ajax({
        url: "/index.php?r=/referencing-model/contained-branch-refs-list",
        method: "post",
        data: { modelId: removeLeaf.getId() },
      }).then(response => {
        this.setState({ subModels: response.data.subModels });
      })
    }
  }


  handleChange = (e) => {
    e.preventDefault();
    const selectedIds = [...this.state.selectedIds];
    const idx = selectedIds.findIndex(id => id === e.target.value);

    if (idx > -1) {
      selectedIds.splice(idx, 1);
    } else {
      selectedIds.push(e.target.value);
    }

    this.setState({ selectedIds });
  }

  onDelete = () => {
    const { removeLeaf } = this.props;
    BasicAlert.show(`Deleting '${removeLeaf.getName()}'`, "Processing request", false);

    let modelIds = [];        // projects
    let subModelIds = [];     // models
    let branchId;             // branch

    if (removeLeaf instanceof ProjectLeaf) {
      modelIds.push(removeLeaf.getId());
      subModelIds = this.state.selectedIds;
    }

    if (removeLeaf instanceof BranchLeaf) {
      return;  // not ready
    }

    if (removeLeaf instanceof ModelLeaf) {
      subModelIds.push(removeLeaf.getId());
    }

    _ajax({
      url: "/index.php?r=/referencing-model/delete-models-sub-models-or-branch",
      method: "post",
      data: {
        modelIds: modelIds.length ? modelIds : null,
        subModelIds: subModelIds.length ? subModelIds : null,
        branchId: branchId || null,
        accountName: null,
        workspaceSwitchList: null,
        secondPass: false,
      }
    }).then(response => {
      BasicAlert.hide();

      // res can be an empty string? - logic from original code
      if (!response.data) {
        actionCreators.receiveErrors("An unexpected error occurred. Please try again or contact Skayl Support for help.");

      } else {
        if (response.data?.deleted === true) {
          if (removeLeaf instanceof ProjectLeaf) {
            actionCreators.receiveLogs("Project successfully deleted.");
          }
          else if (removeLeaf instanceof ModelLeaf) {
            actionCreators.receiveLogs("Model were successfully deleted.");
          }
          else if (removeLeaf instanceof BranchLeaf) {
            actionCreators.receiveLogs("Branch successfully deleted.");
          }

          // refresh tree
          this.props.onDeleteCallback(modelIds, subModelIds);

        } else if (response.warnings) {
          // --- This triggers when deleting a submodel ---

          // backend "Models" = frontend "Projects"
          //    this logic is from original code - filters list down to only unique ids 
          let deletableProjects = new Set();
          if (Array.isArray(response.data.deletableModels)) {
            deletableProjects = new Set(response.data.deletableModels);
            if (removeLeaf instanceof ProjectLeaf) deletableProjects.add(removeLeaf.getId());
          }

          BasicConfirm.show(response.warnings, () => {
            _ajax({
              url: "/index.php?r=/referencing-model/delete-models-sub-models-or-branch",
              method: "post",
              data: {
                  modelIds: deletableProjects.size ? [...deletableProjects] : null,
                  subModelIds: response.data.deletableSubModels?.length > 0 ? response.data.deletableSubModels : null,
                  accountName: null,
                  branchId: null,
                  workspaceSwitchList: response.data.workspaceSwitchList?.length ? response.data.workspaceSwitchList : null,    // Yii converts emptry array to undefined
                  secondPass: true,
              },
            }).then(resp => {
              if (resp.data?.deleted === true) {
                actionCreators.receiveLogs("Models were successfully deleted");

                // refresh trees
                return this.props.onDeleteCallback(modelIds, subModelIds);
              } else if (resp.data?.error) {
                actionCreators.receiveErrors(resp.data.error);
              }

              actionCreators.receiveErrors("An unexpected error occurred. Please try again or contact Skayl Support for help.");
            })
          }).catch(err => {
            BasicAlert.hide();
          })
        }
      }
    }).catch(err => {
      BasicAlert.hide();
    })
  }

  render() {
    const { removeLeaf } = this.props;
    const { subModels, selectedIds } = this.state;
    if (!removeLeaf) return null;

    return <Dialog title="Confirm Delete"
                   className="dialog-no-exit dialog-80vh">

              <div style={{ padding: 5, marginBottom: 5 }}>
                Are you sure you want to delete <b>{removeLeaf.getName()}</b>?
              </div>

              {subModels.length > 0 &&
              <div style={{ display: "flex", flexDirection: "column", padding: 5, gap: 5 }}>
                <i>Select model(s) to be deleted with the project:</i>
                <select id="submodel-list" 
                        className="cadet-select" 
                        size="6"
                        style={{ margin: 0, width: "100%" }}
                        value={selectedIds}
                        onChange={this.handleChange}
                        multiple>
                  {subModels.map((subModel) => {
                    return <option key={subModel.id} value={subModel.id}>{subModel.name}</option>
                  })}
                </select>
              </div> }

              <DialogActionsBar>
                <button className="k-button"
                        onClick={() => {
                          this.props.hide();
                        }}>No</button>
                <button className="k-button k-primary" data-type="basic-confirm" autoFocus={true}
                        id={"confirm-delete"}
                        onClick={async () => {
                          this.props.hide();
                          await this.onDelete();
                        }}>Yes</button>
              </DialogActionsBar>
            </Dialog>
  }
}

export const ManageSubMenu = (props) => {
  // disables the link through css
  const disableLink = props.loading ? " disabled" : "";
  const isReviewProject = props.isReviewProject;

  return <nav className="sub-menu-actions" aria-label='form actions' style={{paddingLeft: 0}}>
          <SubMenuLeft>
            <nav className="manage-sub-menu" aria-label='sub menu'>
              <NavLink to="/manage/branch/model-manage" activeClassName="active" className={disableLink}>MANAGE PROJECTS</NavLink> 
              {!isReviewProject && <>                 
                <NavLink to="/manage/branch/push" activeClassName="active" className={disableLink}>PUSH CHANGES</NavLink>
                <NavLink to="/manage/branch/pull" activeClassName="active" className={disableLink}>PULL CHANGES</NavLink>
                <NavLink to="/manage/branch/approve" activeClassName="active" className={disableLink}>APPROVE CHANGES</NavLink>    
              </>}

              {props.loading &&
                <img id="loading-spinner"
                    style={{ width: 30 }}
                    src={loadingIcon} /> }
            </nav>
          </SubMenuLeft>

          {props.children &&
          <SubMenuRight>
            { props.children }
          </SubMenuRight> }
          </nav>
}






const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  canEdit: state.user.canEdit,
  isReviewProject: state.user.isReviewProject,
})


export default connect(msp)(ModelManager);
