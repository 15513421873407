import { createNodeUrl } from "../../requests/type-to-path.js";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isPhenomGuid } from "../util/util.js";
import NavTree from "../tree/NavTree.jsx";

export const PhenomButtonLink = withRouter(({ node, history, newTab, id, className }) => {
  const classes = ["k-icon", "k-i-hyperlink-open"];
  classes.push(className);
  const url = createNodeUrl(node);

  if (!url || url === "#" || isPhenomGuid(node?.guid)) {
    return null;
  }

  return <Link to={url} className={classes.join(' ')} target={newTab ? "_blank" : null}/>
})

export function findNodeLeafParents(guid, result=[]) {
  const leaf = NavTree.getLeafNode(guid);
  if (!leaf) return result;

  const parent = leaf.getParentLeaf();
  const packages = ["datamodel:DataModel", "face:ConceptualDataModel", "face:LogicalDataModel", "face:PlatformDataModel", "face:UoPModel"];
  if (!parent || packages.includes(parent.getXmiType())) {
    return result;
  }

  result.unshift(parent.getData());
  return findNodeLeafParents(parent.getGuid(), result);
}

// uses NavTree to add parent/grandparent data
export function addParentForPhenomLink(node) {
  if (!node) return;
  
  const addParentTypes = [
      "platform:CharacteristicProjection",
      "conceptual:Composition",
      "conceptual:AssociatedEntity",
      "platform:EnumerationLiteral",
      "platform:IDLComposition",
      "logical:EnumerationConstraint",
      "logical:ReferencePoint",
      "logical:EnumerationLabel",
      "logical:MeasurementAttribute",
      "logical:ReferencePointPart",
      "uop:MessagePort",
      "skayl:UnionCase",
  ];

  const addGrandparentTypes = [
      "skayl:UnionCase",
  ];
  
  if (addParentTypes.includes(node.xmiType)) {
      const parentLeaf = NavTree.getLeafNode(node.parent);
      if (!parentLeaf) return;

      node.parent = parentLeaf.getData();

      if (addGrandparentTypes.includes(node.xmiType)) {
          const grandparentLeaf = NavTree.getLeafNode(node.parent.parent);
          if (!grandparentLeaf) return;

          node.parent.parent = grandparentLeaf.getData();
      }
  }
}

export const PhenomLink = ({node={}, newTab, idCtx = "", showParent, showGrandparent, stagger = false, onClick}) => {
    const displayName = node.name || node.rolename || node.xmiType;
    const url = createNodeUrl(node);
    idCtx += "-phenom-link"

    // XmiType does not have a page
    if (url === "#") {
      return (
        <span>
          {showGrandparent && showParent && typeof node.parent?.parent === 'object' && (
            <>
              <PhenomLink node={node.parent.parent} newTab={newTab} idCtx={idCtx} />
              {stagger && <br />}
              .
            </>
          )}
          {showParent && typeof node.parent === 'object' && (
            <>
              <PhenomLink node={node.parent} newTab={newTab} idCtx={idCtx} />
              {stagger && <br />}
              .
            </>
          )}
          {displayName}
        </span>
      );
    }

    if (typeof showParent !== 'boolean') {
      showParent = !node.name && !!node.parent?.guid && !!node.parent?.xmiType && !!node.parent?.name;
    }

    return <span>
        {showGrandparent && showParent && typeof node.parent?.parent === 'object' && <>
            <PhenomLink node={node.parent.parent} newTab={newTab} idCtx={idCtx} />
            {stagger && <br />}
            .
        </>}
        {showParent && typeof node.parent === 'object' && <>
            <PhenomLink node={node.parent} newTab={newTab} idCtx={idCtx} />
            {stagger && <br />}
            .
        </>}
        <Link id={idCtx}
              className="cadet-anchor"
              to={{
                pathname: url,
                state: { 
                  scrollToGuid: node.guid,
                  guid: node.guid, 
                  xmiType: node.xmiType
                }}}
              target={newTab ? "_blank" : null}
              onClick={onClick}
              disabled={(!node.xmiType && !node["xmi:type"]) || (!node.guid && !node["xmi:id"]) || node.in_model === false}>
            {displayName}
        </Link>
    </span>
}
