/**
 * Provide a model node and get back the proper url
 *    if the provided node has an incorrect format then the url default to "/"
 *
 * @param {node} node
 */
export function createNodeUrl(node) {
  // old code uses various xmiTypes
  // --> Measurement System's related_measurement uses node.xmi_type
  const xmiType = node?.xmiType || node?.["xmi:type"] || node?.xmi_type;

  if (!node || (!node.guid && !node["xmi:id"]) || !xmiType || node.in_model === false) {
    return "#";
  }

  // url = /:mode/:main_page/:sub_page/:node_guid/:parent_guid/:parent_name
  let mode = "edit";
  let main_page = "details";
  let sub_page;
  let node_guid = node.guid || node['xmi:id'];
  // let parent_guid = "";

  switch (xmiType) {
    case "datamodel:DataModel":
    case "face:ConceptualDataModel":
    case "face:LogicalDataModel":
    case "face:PlatformDataModel":
    case "skayl:DiagramModel":
      sub_page = "package";
      break;
    case "face:UoPModel":
    case "skayl:MessageDataModel":
    case "skayl:IntegrationModel":
    case "skayl:DeploymentModel":
      mode = "integration";
      sub_page = "package";
      break;
    case "conceptual:Entity":
    case "conceptual:Association":
      sub_page = "entity";
      break;
    case "conceptual:AssociatedEntity":
    case "conceptual:Composition":
      sub_page = "entity";
      node_guid = typeof node.parent === 'string' ? node.parent : node.parent?.guid;
      break;
    case "conceptual:BasisEntity":
      sub_page = "basis_entity";
      break;
    case "conceptual:Domain":
      sub_page = "domain";
      break;
    case "platform:Boolean":
    case "platform:Char":
    case "platform:WChar":
    case "platform:String":
    case "platform:WString":
    case "platform:Double":
    case "platform:LongDouble":
    case "platform:Float":
    case "platform:Long":
    case "platform:LongLong":
    case "platform:ULong":
    case "platform:ULongLong":
    case "platform:Short":
    case "platform:UShort":
    case "platform:Octet":
    case "platform:Fixed":
    case "platform:BoundedString":
    case "platform:CharArray":
    case "platform:IDLArray":
    case "platform:IDLSequence":
    case "platform:WCharArray":
    case "platform:BoundedWString":
    case "platform:Enumeration":
    case "platform:IDLStruct":
    case "platform:IDLComposition":
      sub_page = "platform_type";
      break;
    case "platform:View":
      sub_page = "view";
      break;
    case "platform:CharacteristicProjection":
      if (node.viewType) {
        sub_page = "view";
        node_guid = typeof node.viewType === 'string' ? node.viewType : node.viewType?.guid;
      } else {
        sub_page = "characteristic";
      }
      break;
    case "conceptual:Observable":
      sub_page = "observable";
      break;
    case "logical:Measurement":
      sub_page = "measurement";
      break;
    case "logical:MeasurementAxis":
      sub_page = "measurement_axis";
      break;
    // case "logical:MeasurementConstraint":
    case "logical:EnumerationConstraint":
    case "platform:RegularExpressionConstraint":
    case "platform:RealRangeConstraint":
    case "platform:IntegerRangeConstraint":
      sub_page = "constraint";
      break;
    case "logical:Unit":
      sub_page = "unit";
      break;
    case "logical:ReferencePoint":
      sub_page = "reference_point";
      break;
    case "logical:MeasurementSystem":
      sub_page = "measurement_system";
      break;
    case "logical:MeasurementSystemAxis":
      sub_page = "measurement_system_axis";
      break;
    case "logical:CoordinateSystem":
      sub_page = "coordinate_system";
      break;
    case "logical:CoordinateSystemAxis":
      sub_page = "coordinate_system_axis";
      break;
    case "logical:ValueTypeUnit":
      sub_page = "value_type_unit";
      break;
    case "logical:Boolean":
    case "logical:Character":
    case "logical:Enumerated":
    case "logical:Integer":
    case "logical:Natural":
    case "logical:NonNegativeReal":
    case "logical:Real":
    case "logical:String":
      sub_page = "value_type";
      break;
    case "logical:MeasurementConversion":
    case "logical:MeasurementSystemConversion":
    // case "logical:Conversion":
      sub_page = "conversion";
      break;
    case "logical:Landmark":
      sub_page = "landmark";
      break;
    case "logical:StandardMeasurementSystem":
      sub_page = "standard_measurement_system";
      break;
    case "skayl:DiagramContext":
      main_page = "scratchpad";
      break;
    case "ddm:MainProgram":
      mode = "integration";
      sub_page = "main_program";
      break;
    case "pedm:ProcessingElement":
      mode = "integration";
      sub_page = "processing_element";
      break;
    case "uop:PortableComponent":
    case "uop:PlatformSpecificComponent":
      mode = "integration";
      sub_page = "portable_component";
      break;
    case "im:UoPInstance":
      mode = "integration";
      sub_page = "uop_instance";
      break;
    case "im:TransportChannel":
      mode = "integration";
      sub_page = "transport_channel";
      break;
    case "message:Type":
      mode = "integration";
      sub_page = "message";
      break;
    case "im:IntegrationContext":
    case "im:ComposedBlock":
      mode = "integration";
      main_page = "idm";
      break;
    default:
      return "#";
  }

  let url = `/${mode}/${main_page}`;
  if (sub_page) url += "/" + sub_page;
  if (node_guid) url += "/" + node_guid;
  return url;
}

// Used in health_check
export function typeToPath(xmiType){
  switch (xmiType) {
    case "face:ConceptualDataModel":
    case "face:LogicalDataModel":
    case "face:PlatformDataModel":
      return "package";
    case "conceptual:Entity":
    case "conceptual:Association":
    case "conceptual:AssociatedEntity":
    case "conceptual:Composition":
      return "entity";
    case "platform:Boolean":
    case "platform:Char":
    case "platform:WChar":
    case "platform:String":
    case "platform:WString":
    case "platform:Double":
    case "platform:LongDouble":
    case "platform:Float":
    case "platform:Long":
    case "platform:LongLong":
    case "platform:ULong":
    case "platform:ULongLong":
    case "platform:Short":
    case "platform:UShort":
    case "platform:Octet":
    case "platform:Fixed":
    case "platform:BoundedString":
    case "platform:CharArray":
    case "platform:IDLArray":
    case "platform:IDLSequence":
    case "platform:WCharArray":
    case "platform:BoundedWString":
    case "platform:Enumeration":
    case "platform:IDLStruct":
    case "platform:IDLComposition":
      return "platform_type";
    case "platform:View":
      return "view";
    case "platform:CharacteristicProjection":
      return "characteristic";
    case "uop:PortableComponent":
      return "portable_component";
    case "uop:PortableComponent":
      return "portable_component";
    case "conceptual:Observable":
      return "observable";
    case "logical:Measurement":
      return "measurement";
    case "uop:MessagePort":
      return "message_port";
    // case "logical:MeasurementConstraint":
    case "logical:EnumerationConstraint":
      return "constraint";
    case "logical:Unit":
      return "unit";
    case "logical:ReferencePoint":
      return "reference_point";
    case "logical:MeasurementSystem":
      return "measurement_system";
    case "logical:MeasurementSystemAxis":
      return "measurement_system_axis";
    case "logical:CoordinateSystem":
      return "coordinate_system";
    case "logical:CoordinateSystemAxis":
      return "coordinate_system_axis";
    case "logical:MeasurementConversion":
    case "logical:MeasurementSystemConversion":
    // case "logical:Conversion":
      return "conversion";
    case "logical:Landmark":
      return "landmark";
    case "logical:StandardMeasurementSystem":
      return "standard_measurement_system";
    // case "conceptual:AssociatedEntity":
    //   return "";
    case "ddm:MainProgram":
      return "main_program";
    case "im:UoPInstance":
      return "uop_instance";
    case "pedm:ProcessingElement":
      return "processing_element";
    case "im:TransportChannel":
     return "transport_channel";
    default:
      return ""
  }
}

export function typeToPathParent(xmiType, child, parent){
  const ttp = typeToPath(xmiType);
  if (ttp === "" ) {
    return `/edit/details/${typeToPath(parent.xmiType)}/${parent.guid}/`
  }else{
    return `/edit/details/${ttp}/${child.guid}/`
  }
}
