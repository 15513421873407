import AttributeRuleModel from "./AttributeRuleModel";


class NumberRule extends AttributeRuleModel {
  range = [-Infinity, Infinity];
  notEqual = null;

  constructor(ruleset={}) {
    super();

    const { flags=[], rules={} } = ruleset;
    this.parseFlags(flags);
    this.parseRules(rules);
  }

  parseRules(rules = {}) {
    if (!rules) {
      return;
    }

    super.parseRules(rules);

    let range;
  
    Object.keys(rules).forEach((key) => {
      const value = rules[key];
  
      switch (key) {
        case "in-range":
          if (Array.isArray(value)) {
            range = value;
          }
          break;

        case "not-eq":
          this.notEqual = value;
          break;
        
        default:
      }
    })
  
    if (Array.isArray(range)) {
      this.range = range;
    }
  }

  getNotEqual() {
    const num = Number(this.notEqual);

    if (num !== NaN) {
      return num;
    }

    return null;
  }
}



export default NumberRule;