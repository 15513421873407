import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Branch from "../components/manage/branch";
import {Migrate} from "../components/manage/migrate";
import {ChangeSets} from "../components/manage/change_sets";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import * as actionThunks from "../requests/actionThunks"
import PermissionTokens from "../components/manage/branch/permission_tokens";
import { routeUrlBuilder } from "../routes";
import Review from "../components/manage/review";

class Manage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // old code
            treeExpanded: true,
            popupOpen: false,
            treeRef: null,
        };
        window["whereSelected"] = "manage";
    }

    componentDidMount() {
      actionThunks.getActiveChangeSets();

      // remove diagram's recovery json data - only exist if the user is auto logged out
      sessionStorage.removeItem("view_trace recoverFile");
      sessionStorage.removeItem("idm_editor recoverFile");
      sessionStorage.removeItem("spRecover");
    }

    componentDidUpdate() {
        if (new RegExp(/\/manage(\/branch)?(?!\/)/).test(this.props.location.pathname)) {
            this.props.history.replace("/manage/branch/model-manage");
        } else if (new RegExp(/\/manage(\/import)?(?!\/)/).test(this.props.location.pathname)) {
            this.props.history.replace("/manage/import/import_csv");
        }
    }

    toggleTree = () => {
        this.setState({
            treeExpanded: !this.state.treeExpanded
        });
    };

    pageName() {
        return this.props.location.pathname.split("/")[3];
    }

    render() {
        const { isAdmin, canEdit, expired, isReviewProject } = this.props;
      
        return (<>
            <Helmet>
                <title>Manage | {this.props.userIdentity.branchName} Project</title>
            </Helmet>

            <section id="phenom-content" aria-label='content'>
                <nav className="sub-menu-pages" aria-label='sub menu'>
                    <NavLink id="branch-link" activeClassName="active" to="/manage/branch">PROJECTS</NavLink>
                    {/* {canEdit && !expired && <>
                      <NavLink id="change-sets-link" activeClassName="active" to="/manage/change_sets">CHANGE SETS</NavLink>
                    </>} */}
                    {isReviewProject && 
                        <NavLink id="review-link" activeClassName="active" to="/manage/review">REVIEW</NavLink>
                    }
                    <NavLink id="token-link" activeClassName="active" to="/manage/permission_tokens">Project Sharing</NavLink>
                </nav>

              <Switch>
                <Route path={routeUrlBuilder("manage", "branch")}
                       component={Branch} />
                <Route path={routeUrlBuilder("manage", "gen-spec")} 
                       component={Migrate} />
                {isReviewProject &&
                    <Route path={routeUrlBuilder("manage", "review")}
                        component={Review} />
                }
                {/* <Route path="/manage/change_sets" component={ChangeSets} /> */}
                <Route path={routeUrlBuilder("manage", "permission_tokens")} 
                       component={PermissionTokens}/>
                <Redirect to="/manage/branch" />
              </Switch>
            </section>
        </>);
    }
}



const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  canEdit: state.user.canEdit,
  expired: state.user.expired,
  isAdmin: state.user.isAdmin,
  isReviewProject: state.user.isReviewProject,
})


export default connect(msp)(Manage);
