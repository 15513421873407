import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { _ajax } from "../../requests/sml-requests";
import { routeUrlBuilder } from "../../routes";
import Commit from "./review/edit-commits";
import FinalizeMerge from "./review/finalize-merge";
import UpdateReview from "./review/update-review";
import { SubMenuLeft, SubMenuRight } from "../edit/edit-top-buttons";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif"

class Review extends React.Component {
    render() {
        const { isReviewProject } = this.props;
        if (!isReviewProject) return;

        return (
            <Switch>
                <Route path={routeUrlBuilder("manage", "review", "finalize-merge")} 
                        component={FinalizeMerge} />
                <Route path={routeUrlBuilder("manage", "review", "commit")}  
                        component={Commit}/>
                <Route path={routeUrlBuilder("manage", "review", "update-review")}  
                        component={UpdateReview}/>
                <Redirect to="/manage/review/finalize-merge" />
            </Switch>
        );
    }
}

export const ReviewSubMenu = (props) => {
    // disables the link through css
    const disableLink = props.loading ? " disabled" : "";
  
    return <nav className="sub-menu-actions" aria-label='form actions' style={{paddingLeft: 0}}>
            <SubMenuLeft>
              <nav className="manage-sub-menu" aria-label='sub menu'>
                <NavLink to="/manage/review/finalize-merge" activeClassName="active" className={disableLink}>FINALIZE MERGE</NavLink>                
                <NavLink to="/manage/review/commit" activeClassName="active" className={disableLink}>COMMITS</NavLink>
                <NavLink to="/manage/review/update-review" activeClassName="active" className={disableLink}>UPDATE REVIEW</NavLink>
                
                {props.loading &&
                  <img id="loading-spinner"
                      style={{ width: 30 }}
                      src={loadingIcon} /> }
              </nav>
            </SubMenuLeft>
  
            {props.children &&
            <SubMenuRight>
              { props.children }
            </SubMenuRight> }
            </nav>
  }


const msp = (state) => ({
  expired: state.user.expired,
  isReviewProject: state.user.isReviewProject,
  userIdentity: state.user.userIdentity,
})


export default connect(msp)(Review)