import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import SkaylMetaModel from '../../smm/SkaylMetaModel';
import FixUpNode from './FixUpNode';
import ListPaneView from '../edit/list-pane-view';
import { PhenomCollapsable, PhenomLabel, PhenomSelect } from '../util/stateless';
import NavTree from '../tree/NavTree';
import SkaylComponentFactory from '../../smm/factory/SkaylComponentFactory';
import { modelGetNode, moveNode, smmSaveNodes } from '../../requests/sml-requests';
import RawAttrGrid from '../widget/RawAttrGrid';
import PhenomLoadButton from '../widget/LoaderButton';
import ReferenceRule from '../../smm/rules/ReferenceRule';
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import { SubMenuLeft } from '../edit/edit-top-buttons';
import { NavLink } from 'react-router-dom';
import EnumerationRule from '../../smm/rules/EnumerationRule';
import { findNodeLeafParents } from '../widget/PhenomLink';
import { connect } from 'react-redux';
import { receiveErrors } from '../../requests/actionCreators';


class FixUp extends React.Component {

  state = {
    smm: new SkaylMetaModel(),
    activeFixUpNode: null,
    fixupNodesMap: {},        // contains error messages
    fixupNodeGroupings: [],   // for sorting data
    editedNodes: new Set(),

    groupBy: "xmi:type",
    message: null,
  }
  
  componentDidMount() {
    NavTree.collapseNavTree(true);
    NavTree.reset().then(() => this.fetchErrors());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.groupBy !== this.state.groupBy) {
      this.createListSections();
    } else if (prevState.activeFixUpNode !== this.state.activeFixUpNode) {
      this.fetchNodeData();
    }
  }

  reset = () => {
    this.setState({
      activeFixUpNode: null,
      fixupNodeGroupings: [],
      fixupNodesMap: {},
      editedNodes: new Set(),
      message: null,
    })
  }

  fetchErrors = () => {
    this.reset();

    return $.ajax({
      url: "/index.php?r=/referencing-model/meta-model-errors",
    }).then((res) => {
      const response = JSON.parse(res);
      const errors = response.data?.errors;
      const smm = response.data?.smm;
      
      if (!Array.isArray(errors) && !smm) {
        return this.setState({
          message: "Unfortunately, model validation failed to complete. This may, in fact, be due to invalid or corrupted model content. Please report this issue to Skayl support. In the meantime, you may attempt to roll-back your review project to a known working commit-point or delete and re-initialize your reivew workspace with different content."
        });
      }

      const fixupNodesMap = {}

      // create smm rules
      if (smm) {
        Object.keys(smm).forEach((xmiType) => {
          const ruleset = smm[xmiType];
          this.state.smm.addRuleset(xmiType, ruleset);
        })
      }

      // find error nodes
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          let node = fixupNodesMap[err.guid];
          
          if (node) {
            node.addErrorData(err);
          } else {
            err.name = this.formatFixUpName(err);
            fixupNodesMap[err.guid] = new FixUpNode(err);
          }
        })
      }

      this.setState({ fixupNodesMap }, 
        () => {
          this.createListSections();
        });
    })
  }

  formatFixUpName(err) {
    const findName = (n) => n["name"] || n["rolename"] || n["xmiType"];

    const names = findNodeLeafParents(err.guid).map(n => findName(n));
    names.push(err.name);

    return names.join(".");
  }

  createListSections() {
    const { groupBy, fixupNodesMap } = this.state;

    let dataList = [];
    const nodes = Object.values(fixupNodesMap);

    switch (groupBy) {
      case "xmi:type":
        let xmiTypes = new Set(nodes.map(er => er.getXmiType()));
        [...xmiTypes].sort().forEach((xmiType) => {
          dataList.push({
            collapsible: true,
            collapsed: false,
            data: nodes.filter((er) => er.getXmiType() === xmiType)
                       .map((er) => er.getFixUpData())
                       .sort((n1, n2) => n1.name.localeCompare(n2.name)),
            columns: [{header: xmiType, key: "name", flex: 5},
                      {header: "Errors", key: "errors", flex: 5},
                      {header: "Edited", render: this.renderEditedCheckbox, flex: 1}],
          })
        });
      break;

      case "parent":
        let parentGuids = new Set(nodes.map(er => er.getParentUUID()));
        let parents = [...parentGuids].map((parentGuid) => {
          const parentLeaf = NavTree.getNodeData(parentGuid);
          return {
            name: parentLeaf?.name || parentGuid,
            guid: parentGuid,
          }
        })

        parents.sort((n1, n2) => n1.name.localeCompare(n2.name))
               .forEach((parent) => {
          dataList.push({
            collapsible: true,
            collapsed: false,
            data: nodes.filter((er) => er.getParentUUID() === parent.guid)
                       .map((er) => er.getFixUpData())
                       .sort((n1, n2) => n1.name.localeCompare(n2.name)),
            columns: [{header: parent.name, key: "name", flex: 5},
                      {header: "Errors", key: "errors", flex: 5},
                      {header: "Edited", render: this.renderEditedCheckbox, flex: 1}],
          })
        })
        break;
    }

    this.setState({
      fixupNodeGroupings: dataList,
      message: nodes.length < 1 ? "No validation issues discovered." : null,
    })
  }

  updateSelectedNode = (guid, attr, value) => {
    const { fixupNodesMap } = this.state;

    const fixupNode = fixupNodesMap[guid];
    if (!fixupNode) {
      return;
    }

    const node = fixupNode.getNodeData();
    if (!node) {
      return;
    }

    const editedNodes = new Set(this.state.editedNodes);
    editedNodes.add(guid);

    if (attr === "parent") {
      fixupNode.prepareMoveNode(value);
    } else {
      node[attr] = value;
      fixupNode.setNodeData(node);
    }

    this.setState({
      editedNodes,
    });
  }

  onSelectNode = (el) => {
    const { fixupNodesMap } = this.state;

    const node = fixupNodesMap[el.guid];
    this.setState({ activeFixUpNode: node || null });
  }

  fetchNodeData = () => {
    const { activeFixUpNode } = this.state;

    if (!activeFixUpNode) {
      return;
    }

    // node exist, don't refetch
    if (activeFixUpNode.getNodeData()) {
      return;
    }

    modelGetNode(activeFixUpNode.getUUID()).then((response) => {
      if (!response?.guid) {
        return;
      }

      response.children = [];

      activeFixUpNode.setNodeData(response);
      this.forceUpdate();
    })
  }

  handleSaveNodes = () => {
    const { dataNodesMap, editedNodes, fixupNodesMap } = this.state;
    
    if (!editedNodes.size) {
      return;
    }
    const fixupNodes = [...editedNodes].map((guid) => fixupNodesMap[guid]);
    const moveNodes = fixupNodes.filter((n) => !!n.getMoveTo());

    Promise.all((
      moveNodes.map((mn) => {
        return moveNode({
          treeNodes: [mn.getUUID()],
          new_parent_guid: mn.getMoveTo(),
        })
      })
    )).then((responses) => {
      const failures = responses.filter(res => res?.status !== "success");

      // MOVE NODE FAILED
      if (failures.length) {
        let errors = [];
        failures.forEach((err) => {
          if (Array.isArray(err.errors) && err.errors.every(err => typeof err === 'string')) {
            errors = errors.concat(err.errors);
          }
        })

        receiveErrors(errors);
        NavTree.reset();
        return;
      }

      // SAVE EDITED NODES
      const nodes = fixupNodes.map((fix) => fix.getNodeData());

      return smmSaveNodes({
        nodes,
        reviewSmmFixup: this.props.isReviewProject || false,
        returnFullError: true
      }).then((response) => {
        const newFixUpNodes = {};

        if (Array.isArray(response.data?.errors)) {
          response.data.errors.filter((err) => !!err?.guid).forEach((err) => {
            let node = newFixUpNodes[err.guid];
      
            if (node) {
              node.addErrorData(err);
            } else {
              err.name = this.formatFixUpName(err);
              newFixUpNodes[err.guid] = new FixUpNode(err);
            }
          });
        }

        this.setState((prevState) => {
          const fixupNodesMap = {...prevState.fixupNodesMap};
          [...prevState.editedNodes].forEach((guid) => {
            delete fixupNodesMap[guid];
          })

          return {
            activeFixUpNode: null,
            editedNodes: new Set(),
            fixupNodesMap: {
              ...fixupNodesMap,
              ...newFixUpNodes,
            }
          }
        }, () => {
          NavTree.reset().then(() => {
            this.createListSections();
          })
        })
      })

    })


    
  }

  renderEditedCheckbox = (el) => {
    const { editedNodes } = this.state;

    if (editedNodes.has(el.guid)) {
      return <input type="checkbox"
                    checked={true}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState((prevState) => {
                        const guids = new Set(prevState.editedNodes);
                        guids.delete(el.guid);

                        const fixUps = {...prevState.fixupNodesMap}
                        const fixUp = fixUps[el.guid];
                        if (fixUp) fixUp.setNodeData(null);

                        // remove the currently selected node if the guid matches
                        let fixupNode = prevState.activeFixUpNode;
                        if (prevState.activeFixUpNode && prevState.activeFixUpNode.getUUID() === el.guid) {
                          fixupNode = null;
                        }

                        return {
                          activeFixUpNode: fixupNode,
                          fixupNodesMap: fixUps,
                          editedNodes: guids,
                        }
                      })
                    }}
      />
    }

    return null;
  }

  renderPaneContent = () => {
    const { activeFixUpNode, smm } = this.state;

    if (!activeFixUpNode) {
      return null;
    }

    return <SidePanel 
              smm={smm}
              selectedFixUpNode={activeFixUpNode}
              updateSelectedNode={this.updateSelectedNode}
    />
  }

  render() {
    const { activeFixUpNode, fixupNodesMap, groupBy, fixupNodeGroupings, message } = this.state;
    const isLoading = message === null && !Object.keys(fixupNodesMap).length;

    return <div className="phenom-content-wrapper">
      <nav className="sub-menu-actions" aria-label='form actions'>
      <SubMenuLeft>
          <NavLink to="/edit/health_check/report" activeClassName="active">
              Health Checks</NavLink>
          <NavLink to="/edit/health_check/fix_up" activeClassName="active">
              Model Validation</NavLink>
        </SubMenuLeft>
      </nav>
      
      <div style={{ display: "flex", flexDirection: "column", padding: 20, gap: 20, overflow: "auto" }}>
        <div style={{ display: "flex", gap: 20, width: 500, padding: "10px", border: "1px solid #eaeaea", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <PhenomSelect 
              label="Group By:"
              data={["xmi:type", "parent"]}
              value={groupBy}
              onChange={(e) => this.setState({ groupBy: e.target.value })} />
          </div>

          <PhenomLoadButton 
            text="Reevaluate Model"
            onClick={this.fetchErrors} />
          
          <PhenomLoadButton 
            text="Save Changes"
            onClick={this.handleSaveNodes} />
        </div>

        {isLoading 
          ? <div>
              <img id="loading-spinner"
                  style={{ width: 80 }}
                  src={loadingIcon} />
            </div>

          : message ? <div>
              <p>{ message }</p>
            </div>

          : <div style={{overflowY: "hidden", height: "100%"}}>
              <ListPaneView 
                mainKey="guid"
                activeItem={activeFixUpNode ? activeFixUpNode.getFixUpData() : null}
                lists={[
                  { collapsible: false,
                    data: Object.keys(fixupNodesMap),
                    headerOnly: true,
                    headerClass: "main",
                    columns: [{header: "Nodes To Fix"}]},

                  ...fixupNodeGroupings
                ]}
                /* Pane */
                renderPaneContent={this.renderPaneContent}
                minimizePane={!activeFixUpNode}

                /* Config */
                onSelect={this.onSelectNode}
              />
            </div>
        }
      </div>
    </div>;
  }
}



const SidePanel = (props) => {
  // const [ node, setNode ] = useState(null);
  const [ hiddenAttrs, _ ] = useState(new Set(["subModelId", "diagramNodeLoaded", "editor_id", "tag", "tags", "deprecated"]));
  const { selectedFixUpNode, smm, updateSelectedNode } = props;

  if (!selectedFixUpNode || !selectedFixUpNode.getNodeData()) {
    return <div>
      <img id="loading-spinner"
          style={{ width: 80 }}
          src={loadingIcon} />
    </div>
  }

  const node = selectedFixUpNode.getNodeData();
  Object.keys(node).forEach((key) => {
    const attr = node[key];

    if (Array.isArray(attr) && attr.length < 1) {
      return;
    }

    if (attr === undefined || attr === null) {
      delete node[key];
    }
  })
  
  const errorAttrs = selectedFixUpNode.getErrorAttributes();
  const otherAttrs = Object.keys(node).filter((attr) => {
    if (["xmi:id", "guid", "xmi:type", "xmiType"].includes(attr)) {
      return false;
    }

    if (hiddenAttrs.has(attr)) {
      return false;
    }

    return !errorAttrs.includes(attr);
  });

  return <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
    <PhenomLabel text={selectedFixUpNode.getName()} />

    <div>
      <PhenomCollapsable label="Attributes">
        <RawAttrGrid node={node}
                      nodeDereffer={NavTree.getNodeData}
                      hiddenAttrs={hiddenAttrs}
                      /> 
      </PhenomCollapsable>
    </div>

    {errorAttrs.map((errAttr, idx) => {
      return <AttributeEditor 
                key={idx}
                attr={errAttr}
                smm={smm}
                node={node}
                selectedFixUpNode={selectedFixUpNode}
                updateSelectedNode={updateSelectedNode}
      />
    })}

    {otherAttrs.map((attr, idx) => {
      return <AttributeEditor 
          key={idx}
          attr={attr}
          smm={smm}
          node={node}
          selectedFixUpNode={selectedFixUpNode}
          updateSelectedNode={updateSelectedNode}
      />
    })}
  </div>;
}

const AttributeEditor = (props) => {
  const { smm, node, attr, selectedFixUpNode, updateSelectedNode} = props;

  const ruleset = smm.getRuleset(selectedFixUpNode.getXmiType());
  const rule = ruleset.getRule(attr);
  const targetValue = node[attr];
  let multi, bound, errorMsg, xmiTypes, enumList;

  // some attributes may not have a frontend smm rule
  if (rule) {
    bound = rule.getBound();
    multi = bound[1] && (bound[1] > 1 || bound[1] === -1);
  }

  if (selectedFixUpNode.hasError(attr)) {
    errorMsg = selectedFixUpNode.getErrorMessage(attr);
  }

  if (rule instanceof ReferenceRule) {
    xmiTypes = rule.getReferences();
  }

  if (rule instanceof EnumerationRule) {
    enumList = rule.getEnumeration();
  }

  const Component = SkaylComponentFactory.createAttributeComponent(rule);
  if (!Component) {
    return null;
  }

  return <div style={{ padding: "0px 10px 10px", border: "1px solid #e0e0e0" }}>
    <h3 style={{ fontWeight: 600 }}>{ attr }</h3>
    
    {errorMsg &&
    <p style={{ fontSize: "0.9rem", fontStyle: "italic", color: "hsl(var(--bs-danger-hs) 50%)" }}>
      { errorMsg }</p> }

    <p style={{ fontSize: "0.9rem", fontStyle: "italic" }}>
      Change the value to:</p>

    {multi && Array.isArray(xmiTypes) && typeof targetValue === 'string' 
      ? <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {targetValue.split(" ").map((guid, idx) => {
              return <Component 
                key={idx}
                value={guid}
                xmiTypes={xmiTypes}
                onChange={(val) => {
                  let newValue = targetValue.split(" ");
                  newValue[idx] = val || "";
                  updateSelectedNode(node.guid, attr, newValue.join(" "));
                }}
              />
          })}
        </div>
      
      : <Component 
          value={targetValue}
          xmiTypes={xmiTypes}
          enumList={enumList}
          onChange={(value) => updateSelectedNode(node.guid, attr, value)} />
    }
  </div>
}


const msp = (state) => ({
  isReviewProject: state.user.isReviewProject,
})


export default connect(msp)(FixUp);
