import Draggable from "react-draggable";
import { PhenomLabel } from "../util/stateless";
import React from "react";
import { preReviewNodes } from "../../requests/sml-requests";
import RawAttrGrid from "../widget/RawAttrGrid";
import NavTree from "../tree/NavTree";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif"
import { getShortenedStringRepresentationOfXmiType } from "../util/util";

export class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateNodes: [],
            originalNodes: [],
            loading: true,
        };
    }

    componentDidMount() {
        const { guid, thisNodeOnly } = this.props;

        preReviewNodes(guid, thisNodeOnly)
            .then((res) => {
                const candidateNodes = [];
                const originalNodes = [];

                res.data.nodes.forEach((node) => { //format nodes and build before/after arrays
                    if(node['original-attrs']) node['original-attrs'].xmiType = getShortenedStringRepresentationOfXmiType(node['original-attrs'].xmiType);
                    if(node['candidate-attrs']) node['candidate-attrs'].xmiType = getShortenedStringRepresentationOfXmiType(node['candidate-attrs'].xmiType);

                    candidateNodes.push(node['candidate-attrs']);
                    originalNodes.push(node['original-attrs']);
                })

                this.setState({
                    candidateNodes: candidateNodes,
                    originalNodes: originalNodes,
                    loading: false,
                });
            });
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { candidateNodes, originalNodes, loading } = this.state;
        const { thisNodeOnly } = this.props;
        const boxStyle = {top: window.innerHeight/2 - 300, left: window.innerWidth/3, width: "min-content", maxWidth: "57%", zIndex: 2};

        const descriptionText = (
            <>
                Below is a preview of the proposed attribute changes for the selected node{thisNodeOnly ? "" : " and children under review"}.
                <br/>
                If the proposed changes are accepted and the review is finalized, they will be merged into the destination project. Rejecting the changes will revert the node back to its previous state (Original Node) in the destination project and maintain that state if the rejection is finalized.
            </>
        );

        return (
            <Draggable enableUserSelectHack={false} handle=".dialog-box-header">
                <div className="dialog-box" style={boxStyle}>
                    <span className="dialog-box-header">
                        Review of Proposed Changes
                        <button onClick={this.props.close}>X</button>
                    </span>
                    <div style={{padding: "0 15px", maxHeight: "600px", overflowY: "auto", gap: "1em", display: "flex", flexDirection: "column"}}>
                        {loading ?
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                <img 
                                    id="loading-spinner"
                                    style={{ width: 100, height: 100 }}
                                    src={loadingIcon} 
                                />
                                <p>Fetching preview...</p>
                            </div>
                        : <>
                            <p style={{color: "hsl(246, 24%, 32%)", margin: "10px 0px 0px 0px"}}>
                                {descriptionText}
                            </p>
        
                            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "1em" }}>
                                    <div>
                                        <PhenomLabel text="Original Node"/>
                                        {originalNodes && originalNodes.length &&
                                            <RawAttrGrid
                                                node={originalNodes[0]}
                                                nodeDereffer={(guid) => NavTree.getLeafNode(guid)?.data}
                                            />
                                        }
                                    </div>
                                    {originalNodes && candidateNodes && originalNodes.length > 1 && candidateNodes.length > 1 &&    
                                        <div>
                                            <PhenomLabel text="Children" style={{width: "100%"}}/>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "1em"}}>
                                            {originalNodes.slice(1).map((originalNode, idx) => {
                                                return (<>
                                                    <RawAttrGrid
                                                        node={originalNode}
                                                        nodeDereffer={(guid) => NavTree.getLeafNode(guid)?.data}
                                                    />                                              
                                                </>);
                                            })}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "1em" }}>
                                    <div>
                                        <PhenomLabel text="Proposed Changes"/>
                                        {candidateNodes && candidateNodes.length &&
                                            <RawAttrGrid
                                                node={candidateNodes[0]}
                                                nodeDereffer={(guid) => NavTree.getLeafNode(guid)?.data}
                                            />
                                        }
                                    </div>

                                    {originalNodes && candidateNodes && originalNodes.length > 1 && candidateNodes.length > 1 && 
                                        <div>
                                            <PhenomLabel text="Children" style={{width: "100%"}}/>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: "1em"}}>
                                            {candidateNodes.slice(1).map((candidateNode, idx) => {
                                                return (<>
                                                    <RawAttrGrid
                                                        node={candidateNode}
                                                        nodeDereffer={(guid) => NavTree.getLeafNode(guid)?.data}
                                                    />
                                                </>);
                                            })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </Draggable>
        );
    }
}
