import AttributeRuleModel from "./AttributeRuleModel";


class ReferenceRule extends AttributeRuleModel {
  references = [];

  constructor(ruleset={}) {
    super();

    const { flags=[], rules={} } = ruleset;
    this.parseFlags(flags);
    this.parseRules(rules);
  }

  parseRules(rules = {}) {
    
    if (!rules) {
      return;
    }

    super.parseRules(rules);
      
    Object.keys(rules).forEach((key) => {
      const value = rules[key];
  
      switch (key) {
        case "references":
          if (Array.isArray(value) && value.every(ref => typeof ref === 'string')) {
            this.references = value;
          } else if (typeof value === 'string') {
            this.references = [value];
          }
          break;
        
        // case bound:
        default:
      }
    });
  }

  getReferences() {
    return this.references;
  }
}



export default ReferenceRule;