


class AttributeRuleModel {
  bound = [];
  defaultValue = null;
  optional = false;

  // constructor(ruleset={}) {
  //   const { flags=[], rules={} } = ruleset;
  //   this.parseFlags(flags);
  //   this.parseRules(rules);
  // }

  parseFlags(flags = []) {
    if (!Array.isArray(flags)) {
      return;
    }

    flags.forEach((str) => {
      switch (str) {
        case "optional":
          this.optional = true;
          break;

        default:
      }
    });
  }

  parseRules(rules = {}) {
    if (!rules) {
      return;
    }

    Object.keys(rules).forEach((key) => {
      const value = rules[key];

      switch (key) {
        case "bound":
          if (Array.isArray(value) && value.every(val => typeof val === 'number')) {
            this.bound = value;
          }
          break;

        case "default":
          this.defaultValue = value;
          break;

        default:
      }
    })
  }

  getBound() {
    return this.bound;
  }

  getDefaultValue() {
    return this.defaultValue;
  }

  getOptional() {
    return this.optional;
  }
}

export default AttributeRuleModel;