import React from "react";
import { PhenomLabel } from "../util/stateless";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";

const rawAttrPriority = {
  'xmi:id': 0, 'guid': 0,
  'xmi:type': 1, 'xmiType': 1,
  'name': 2, 'rolename': 2,
  'parent': 3,
  'description': 4, 'descriptionExtension': 4
};
const rawAttrPriorityMax = Math.max.apply(null, Object.values(rawAttrPriority)) + 1;

function assertAsStringArr(value) {
  let values;

  if (typeof value === 'string') {
    values = value.split(' ');
  } else if (Array.isArray(value) && value.every(el => typeof el === 'string')) {
    values = value;
  } else if (typeof value === 'number') {
    values = [ value.toString() ];
  } else if (typeof value === 'boolean') {
    values = [ value.toString() ];
  } else {
    throw "Cannot process node values that are not strings or arrays of strings.";
  }
  return values;
}

function derefAndJoin(values, nodeDereffer) {
  let hasDerefs = false;

  const derefVals = values.map(value => {
    const deref = nodeDereffer(value);

    if (deref) {
      hasDerefs = true;
      return deref.name || deref.rolename;
    } else {
      return value;
    }
  });

  return derefVals.join(hasDerefs ? "\n" : " ");
}

function RawAttrGrid ({node, nodeDereffer, hiddenAttrs = new Set()}) {
  const displayAttrs = Object.entries(node)
                             .filter(([k,v]) => !hiddenAttrs.has(k))
                             .map(([k,v]) => {
                                 if(k !== "xmi:id" && k !== "guid"){
                                   v = derefAndJoin(assertAsStringArr(v), nodeDereffer);
                                 }
                                 return {k,v};
                             });

  displayAttrs.sort((attr1, attr2) => {
    if(attr1.k in rawAttrPriority || attr2.k in rawAttrPriority) {
      return (rawAttrPriority[attr1.k] ?? rawAttrPriorityMax) - (rawAttrPriority[attr2.k] ?? rawAttrPriorityMax);
    }

    return attr1.k.localeCompare(attr2.k);
  });

  return (
    <Grid data={displayAttrs} resizable scrollable="none" style={{ tableLayout: "fixed" }}>
      <Column field="k" title="Attribute" width={"100px"}/>
      <Column field="v" title="Value"/>
    </Grid>
  );
}

export default RawAttrGrid;
