import React from "react";
import PhenomId from "../../../requests/phenom-id";
import { PhenomLabel, ColorTextCollapsible } from "../../util/stateless";
import NavTree from "../../tree/NavTree";
import { BasicAlert } from "../../dialog/BasicAlert";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { Checkbox } from '@progress/kendo-react-inputs';
import { _ajax } from "../../../requests/sml-requests";

class MergeSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        creations: {},
        edits: {},
        deletions: {},
        moves: {},

        categoryChecked: {
          creations: {},
          edits: {},
          deletions: {},
          moves: {},
        },
    }
  }

  componentDidMount() {
    const { page } = this.props
    if (["pull", "push", "update-review"].includes(page)) this.fetchMergeNodes(page);
  }

  sortMergeCandidates = (mergeCandidates) => {
    const { creations, deletions, moves, edits, categoryChecked } = this.state;
    Array.from(mergeCandidates).forEach((leaf) => {
      const xmiType = leaf.getXmiType();
      switch (true) {
        case !!leaf.config.isDeletedForMerge:
          (deletions[xmiType] = deletions[xmiType] || []).push(leaf);
          categoryChecked.deletions[xmiType] = false;
          break;
        case !!leaf.config.isMovedOutForMerge:
          (moves[xmiType] = moves[xmiType] || []).push(leaf);
          categoryChecked.moves[xmiType] = false;
          break;
        case !!leaf.config.isEditedForMerge:
          (edits[xmiType] = edits[xmiType] || []).push(leaf);
          categoryChecked.edits[xmiType] = false;
          break;
        default:
          if (!NavTree.isMovedInNode(leaf.getGuid())) {
            (creations[xmiType] = creations[xmiType] || []).push(leaf);
            categoryChecked.creations[xmiType] = false;
          }
      }
    });
    this.setState({ creations, edits, deletions, moves });
  }

  clearMergeSummary = () => {
    this.setState({
      creations: {},
      edits: {},
      deletions: {},
      moves: {},
      categoryChecked: {
        creations: {},
        edits: {},
        deletions: {},
        moves: {},
      },
    });
  }

  fetchMergeNodes = () => {
    const { page } = this.props;
    this.clearMergeSummary();
    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
    NavTree.fetchTreeWithMergeCandidates(page)
           .finally(() => {
              this.sortMergeCandidates(NavTree.getAllMergeCandidates());
              BasicAlert.hide();
           })
  }

  mergeNodesCount = (mergeTypeNodes) => {
    return Object.values(mergeTypeNodes).reduce((a, v) => a + v.length, 0);
  }

  handleSelectCategory = (e, mergeType) => {
    e.syntheticEvent.stopPropagation();
    const mergeTypeNodes = this.state[mergeType];
    Object.keys(mergeTypeNodes).forEach((xmiType) => {
      if (e.target.element.current.checked && !this.state.categoryChecked[mergeType][xmiType] || 
         !e.target.element.current.checked && this.state.categoryChecked[mergeType][xmiType]) {
        this.handleCheckXmiType(mergeType, xmiType);
      }
    });
  }

  handleCheckXmiType = (mergeType, xmiType) => {
    const { categoryChecked } = this.state;
    const check = !categoryChecked[mergeType][xmiType];
    const mergeNodes = this.state[mergeType][xmiType];

    NavTree.selectMergeCandidates(mergeNodes.map(n => n.getGuid()), check);
    categoryChecked[mergeType][xmiType] = check;
    this.setState({ categoryChecked });
  }
 
  renderCollapsableContent = (mergeType) => {
    const mergeTypeNodes = this.state[mergeType];
    const { categoryChecked } = this.state;
    const phenomId = new PhenomId("merge-summary");
    return Object.keys(mergeTypeNodes).length === 0 ?
      false :
      <div id={phenomId.gen(["merge-nodes",mergeType],"wrapper")}>
        {Object.keys(mergeTypeNodes).map((xmiType) => {              
          return <>
            <span onClick={(e) => e.stopPropagation()}
                  style={{ display: "block", margin: "10px 0px 10px 45px" }}>
              <Checkbox label={getShortenedStringRepresentationOfXmiType(xmiType)}
                        id={phenomId.gen(["collapsable-checkbox",mergeType], xmiType)}
                        checked={categoryChecked[mergeType][xmiType]}
                        onClick={(e) => this.handleCheckXmiType(mergeType, xmiType)} />
              {mergeTypeNodes[xmiType].map((node) => {
                return <p style={{ margin: "0 30px" }}>{node.getName()}</p>
              })}
            </span>
          </>
        })}
      </div>
  }

  getAllSelected = (mergeType) => {
    const xmiTypesChecked = Object.values(this.state.categoryChecked[mergeType]);
    const allSelected = xmiTypesChecked.length > 0 && xmiTypesChecked.reduce((a, v) => a && v, true);

    return allSelected;
  }

  render() {
    const { creations, deletions, moves, edits } = this.state;
    const phenomId = new PhenomId("merge-summary",this.props.idCtx);
    return <div id={phenomId.gen("","wrapper")}>
      <PhenomLabel text="Merge Summary"/>
      <ColorTextCollapsible headerText={"Node Creations"} 
                        color={"#4f9c54"}
                        count={this.mergeNodesCount(creations)}
                        content={this.renderCollapsableContent("creations")}
                        contentId={phenomId.gen(["merge-nodes","creations"],"wrapper")}
                        noToggle={this.mergeNodesCount(creations) === 0}
                        onCheck={(e) => this.handleSelectCategory(e, "creations")}
                        checked={this.getAllSelected("creations")} />
      <ColorTextCollapsible headerText={"Node Edits"} 
                        color={"#489bb3"}
                        count={this.mergeNodesCount(edits)}
                        content={this.renderCollapsableContent("edits")}
                        contentId={phenomId.gen(["merge-nodes","edits"],"wrapper")}
                        noToggle={this.mergeNodesCount(edits) === 0} 
                        onCheck={(e) => this.handleSelectCategory(e, "edits")}
                        checked={this.getAllSelected("edits")} />
      <ColorTextCollapsible headerText={"Node Deletions"} 
                        color={"#8b0000"}
                        count={this.mergeNodesCount(deletions)}
                        content={this.renderCollapsableContent("deletions")}
                        contentId={phenomId.gen(["merge-nodes","deletions"],"wrapper")}
                        noToggle={this.mergeNodesCount(deletions) === 0}
                        onCheck={(e) => this.handleSelectCategory(e, "deletions")}
                        checked={this.getAllSelected("deletions")} />
      <ColorTextCollapsible headerText={"Node Moves"} 
                        color={"#833b83"}
                        count={this.mergeNodesCount(moves)}
                        content={this.renderCollapsableContent("moves")}
                        contentId={phenomId.gen(["merge-nodes","moves"],"wrapper")}
                        noToggle={this.mergeNodesCount(moves) === 0}
                        onCheck={(e) => this.handleSelectCategory(e, "moves")}
                        checked={this.getAllSelected("moves")} />
    </div>;
  }
}
  
export default MergeSummary