import { Children } from "react";
import { isPhenomGuid } from "../../util/util";
import RootLeaf from "./RootLeaf";



/**
 * Branch
 * 
 */
 export function BranchLeaf(data) {
  RootLeaf.call(this);        // inherit from RootLeaf

  this.data = {
    id: data.id || null,
    name: data.name || "",
    description: data.description || "",
    permissions: data.permissions || {},
    externalPerms: data.external_perms || {},
    dateCreated: data.branchCreated || null,
  }

  this.modelLeaves = [];

  //--------------------
  // GETTERS
  //--------------------
  /**
   * Used to save the node's settings. 
   *    - When the page reloads or when the component remounts, the current settings need to be readded.
   *    - ignore "filteredOut" - this is handled by reapplying the filter
   *    - ignore "selected" - the page is reloading and nothing should be selected
   * 
   * @returns javascript object
   */
  this.serializeConfig = () => {
    return { 
      id: this.getId(), 
      config: {
        expanded: this.isExpanded(),
      } 
    }
  }

  this.getId = () => {
    return this.data.id;
  }

  this.getName = () => {
    return this.data.name;
  }

  this.getDescription = () => {
    return this.data.description;
  }

  this.getModelLeaves = () => {
    return this.modelLeaves;
  }

  this.getPermissions = () => {
    return this.data.permissions;
  }

  this.getExternalPermissions = () => {
    return this.data.externalPerms;
  }

  this.getUserPermission = (username) => {
    return this.data.permissions[username];
  }

  this.getExternalPermission = (username) => {
    return this.data.externalPerms[username];
  }

  this.getBranchLeaf = () => {
    return this.branchLeaf;
  }

  this.getProjectLeaf = () => {
    return this.projectLeaf;
  }

  this.getFeauxLeaf = () => {
    return this.projectLeaf;
  }

  this.isAdmin = (username) => {
    return /.*a.*/.test(this.data.permissions[username]);
  }

  this.isOwner = (username) => {
    return /.*o.*/.test(this.data.permissions[username]);
  }

  this.isPublished = () => {
    return false;
  }

  this.isNewLeaf = () => {
    return isPhenomGuid( this.getId() );
  }

  this.getDateCreated = () => {
    return this.data.dateCreated;
  }

  this.getShortdateCreated = () => {
    const dateCreated = this.data.dateCreated;
    let newDate;
    
    newDate = dateCreated.replace(/-/g, '');
    return new Date(newDate.substring(0,4), newDate.substring(4,6), newDate.substring(6,8), newDate.substring(9,11), newDate.substring(12,14), newDate.substring(15,17), newDate.substring(18,24));
  }


  //--------------------
  // SETTERS
  //--------------------
  this.setModelLeaf = (leaf) => {
    if (leaf instanceof ModelLeaf === false) return console.error("TypeError. Cannot set a non-ModelLeaf node");
    !this.modelLeaves.find(ele => leaf === ele) && this.modelLeaves.push(leaf);
  }

  this.setModelLeaves = (leaves=[]) => {
    this.modelLeaves = leaves;
    this.forceUpdate();   // will trigger React.memo's rerender method
  }

  this.setPermissions = (permissions={}) => {
    this.permissions = { ...this.permissions, ...permissions };
    this.forceUpdate();   // will trigger React.memo's rerender method
  }
}

BranchLeaf.prototype = Object.create(RootLeaf.prototype);
BranchLeaf.prototype.constructor = BranchLeaf;



/**
 * Project
 *    - referencing model
 */
export function ProjectLeaf(data) {
  BranchLeaf.call(this, data);          // inherit from BranchLeaf

  this.data = {
    id: data.id || null,
    name: data.name || "",
    description: data.description || "",
    permissions: data.permissions || {},
    externalPerms: data.external_perms || {},
    publishedDate: data.published_time || null,
    dateCreated: data.created || null,
    parentProject: data.parent_project || null,
    projectUnderReview: data.project_under_review || null,
  }

  this.getPublishedDate = () => {
    if (!this.data.publishedDate) return "";

    const date = new Date(this.data.publishedDate);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(date)
  }

  this.getPublishedDateShort = () => {
    if (!this.data.publishedDate) return "";

    const date = new Date(this.data.publishedDate);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(date)
  }

  this.isPublished = () => {
    return this.data.publishedDate?.length > 0;
  }

  this.getProjectParentId = () => {
    return this.data.parentProject;
  }

  this.getProjectUnderReview = () => {
    return this.data.projectUnderReview;
  }

  this.getFeauxChild = (name) => {
    const children = this.getChildrenLeaves();

    const feauxLeaf = children.find(child => child instanceof FeauxLeaf && child.data.name === name);

    if (feauxLeaf) {
        return feauxLeaf;
    }

    return false;
  }

  this.setFeauxLeaf = (leaf) => {
    if (leaf instanceof FeauxLeaf === false) return console.error("TypeError. Cannot set a non-FeauxLeaf node");
    this.feauxLeaf = leaf;
  }
}

ProjectLeaf.prototype = Object.create(RootLeaf.prototype);
ProjectLeaf.prototype.constructor = ProjectLeaf;

/**
 * Project
 *    - referencing model
 */
 export function FeauxLeaf(data) {
  ProjectLeaf.call(this, data);          // inherit from ProjectLeaf

  this.setSelectable(false);
  this.data = {
    name: data.name || "",
    dateCreated: data.branchCreated || null,
  }
}

FeauxLeaf.prototype = Object.create(RootLeaf.prototype);
FeauxLeaf.prototype.constructor = FeauxLeaf;


/**
 * Model
 *    - sub-model 
 */
export function ModelLeaf(data) {
  BranchLeaf.call(this, data);

  // format from /sub-model/users-index-tree"
  // 
  // key          -> values
  // "name"       -> current submodel's name?
  // "model"      -> Branch/Project name?
  // "branchName" -> SubModel/Model/BranchRef name?
  // -------------
  this.data = {
    id: data.id,
    branchId: data.branchId,
    name: data.name || "",
    description: data.description || "",
    publishedDate: data.created || data.publishedDate,
    family: data.family || [],
    dependencies: data.dependencies || [],
    permissions: data.permissions || {},
    externalPerms: data.external_perms || {},
    branchId: data.branchId,
    projectId: null,
    dateCreated: data.branchCreated || null,
    
    // staticBranch: data.staticBranch,    // ???
    // inherited_branch_ref: data.inherited_branch_ref,    // shorthand
  }

  this.branchLeaf = null;
  this.projectLeaf = null;
  this.feauxLeaf = null;

  //--------------------
  // GETTERS
  //--------------------
  this.getFamilyIds = () => {
    return this.data.family;
  }

  this.getDependencies = () => {
    return this.data.dependencies;
  }

  this.getPublishedDate = () => {
    if (!this.data.publishedDate) return "";

    const date = new Date(this.data.publishedDate);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(date)
  }

  this.getPublishedDateShort = () => {
    if (!this.data.publishedDate) return "";

    const date = new Date(this.data.publishedDate);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(date)
  }

  this.isPublished = () => {
    return !!this.data.publishedDate;
  }

  this.findVersionOptions = (modelIndex) => {
    const versionLeaves = [];
    
    if (!modelIndex) {
      return versionLeaves;
    }

    for (let famId of this.getFamilyIds()) {
      const leaf = modelIndex[famId];
      if (!leaf) continue;

      // find published family members
      // find live family members that are not included in another project
      if (leaf.isPublished() || !leaf.getProjectLeaf()) {
        versionLeaves.push(leaf);
      }
    }

    return versionLeaves;
  }

  //--------------------
  // SETTERS
  //--------------------
  this.setBranchLeaf = (leaf) => {
    if (leaf instanceof BranchLeaf === false) return console.error("TypeError. Cannot set a non-BranchLeaf node");
    this.branchLeaf = leaf;
  }

  this.setProjectLeaf = (leaf) => {
    if (leaf instanceof ProjectLeaf === false) return console.error("TypeError. Cannot set a non-ProjectLeaf node");
    this.projectLeaf = leaf;
  }

  this.setSelectable = (bool) => {
    this.config = {
      ...this.config,
      selectable: bool,
    }
    this.getBranchLeaf() && this.getBranchLeaf().forceUpdate();
    this.getProjectLeaf() && this.getProjectLeaf().forceUpdate();
  }

  this.setSelected = (bool) => {
    this.config = {
      ...this.config,
      selected: bool,
    }
    this.getBranchLeaf() && this.getBranchLeaf().forceUpdate();
    this.getProjectLeaf() && this.getProjectLeaf().forceUpdate();
  }

  this.setExpanded = (bool) => {
    this.config = {
      ...this.config,
      expanded: bool,
    }

    // this is only needed because of React.memo
    // it walks up the parent and changes an attribute to trigger the rerender method used by React.memo
    if (this.getBranchLeaf()) {
      bool ? this.getBranchLeaf().setExpanded(bool) : this.getBranchLeaf().forceUpdate();
    }

    if (this.getProjectLeaf()) {
      bool ? this.getProjectLeaf().setExpanded(bool) : this.getProjectLeaf().forceUpdate();
    }

    if (this.getFeauxLeaf()) {
      bool ? this.getFeauxLeaf().setExpanded(bool) : this.getFeauxLeaf().forceUpdate();
    }
  }
}


ModelLeaf.prototype = Object.create(RootLeaf.prototype);
ModelLeaf.prototype.constructor = ModelLeaf;
