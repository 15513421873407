import BooleanRule from "../rules/BooleanRule"
import EnumerationRule from "../rules/EnumerationRule";
import NumberRule from "../rules/NumberRule";
import ReferenceRule from "../rules/ReferenceRule";
import StringRule from "../rules/StringRule";
import TextAreaRule from "../rules/TextAreaRule";


class SkaylMetaModelFactory {

  static createAttributeRule(ruleset={}) {
    const { flags=[], rules={} } = ruleset;

    if (!rules || !Array.isArray(flags)){
      return null;
    }

    const { 
      freeText,
    } = SkaylMetaModelFactory.parseFlags(flags);

    if (freeText) {
      return new TextAreaRule(ruleset);
    }

    const { 
      type,
      references,
      _in,
    } = SkaylMetaModelFactory.parseRules(rules);

    if (_in) {
      return new EnumerationRule(ruleset);
    }

    if (references) {
      return new ReferenceRule(ruleset);
    }

    switch(type) {
      case "boolean":
        return new BooleanRule(ruleset);
      
      case "number":
        return new NumberRule(ruleset);
  
      case "string":
        return new StringRule(ruleset);
  
      default:
    }

    return null;
  }

  static parseFlags(flags=[]) {
    if (!flags) return {};

    let res = {
      freeText: false,
    }

    flags.forEach((flag) => {
      switch (flag) {
        case "free-text":
          res.freeText = true;
          break;

        default:
      }
    })

    return res;
  }

  static parseRules(rules={}) {
    if (!rules) return {};

    let res = {
      type: null,
      references: null,
      _in: null,
    }

    Object.keys(rules).forEach((key) => {
      const value = rules[key];

      switch (key) {
        case "type":
          res.type = value;
          break;
        
        case "references":
          res.references = value;
          break;

        case "in":
          res._in = value;  // used with String
          break;
        
        default:
      }
    });

    return res;
  }
}


export default SkaylMetaModelFactory;