import React, { useState, useEffect } from 'react'
import { PhenomComboBox, PhenomToggle } from '../../util/stateless'
import { Button } from '@progress/kendo-react-buttons'
import ReactTooltip from 'react-tooltip'
import { Checkbox } from "@progress/kendo-react-inputs";



const TreeFilter = (props) => {
  const [filterBy, setFilterBy] = useState("tags")

  return <div className='navtree-filter'>
          <header>
            <h1>Filter by</h1>
              <button className={filterBy === "tags" ? "active" : null}
                      onClick={() => setFilterBy("tags")}>
                  Tag
              </button>
              <button className={filterBy === "type" ? "active" : null}
                      onClick={() => setFilterBy("type")}>
                  Type
              </button>
          </header>

          {filterBy === "tags"
            ? <FilterByTags mode={props.mode}
                            allTags={props.allTags} 
                            useAndTags={props.useAndTags}
                            includeTags={props.includeTags}
                            excludeTags={props.excludeTags}
                            setTagFilters={props.setTagFilters} />

            : <FilterByType mode={props.mode}
                            includeXmiTypes={props.includeXmiTypes}
                            availableXmiTypes={props.availableXmiTypes}
                            setXmiTypeFilters={props.setXmiTypeFilters} />
          }
  </div>
}




const FilterByTags = (props) => {
  const [tagList, setTagList] = useState(props.allTags);
  const [useAndTags, setUseAndTags] = useState(props.useAndTags);
  const [includeTags, setIncludeTags] = useState(props.includeTags);
  const [excludeTags, setExcludeTags] = useState(props.excludeTags);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [])

  useEffect(() => {
    props.useAndTags !== useAndTags && setUseAndTags(props.useAndTags);
  }, [props.useAndTags])

  useEffect(() => {
    props.includeTags !== includeTags && setIncludeTags(props.includeTags);
  }, [props.includeTags])

  useEffect(() => {
    props.excludeTags !== excludeTags && setExcludeTags(props.excludeTags);
  }, [props.excludeTags])

  useEffect(() => {
    const tags = props.allTags.filter((tag) => !includeTags.has(tag) && !excludeTags.has(tag));
    setTagList(tags);
  }, [props.allTags, includeTags, excludeTags])

  const addIncludeFilter = (ele) => {
    const tags = new Set(includeTags).add(ele);
    setIncludeTags(tags);
  }

  const addExcludeFilter = (ele) => {
    const tags = new Set(excludeTags).add(ele);
    setExcludeTags(tags);
  }

  const removeIncludeFilter = (ele) => {
    const tags = new Set(includeTags);
    tags.delete(ele);
    setIncludeTags(tags);
  }

  const removeExcludeFilter = (ele) => {
    const tags = new Set(excludeTags);
    tags.delete(ele);
    setExcludeTags(tags);
  }

  const clearFilters = () => {
    setIncludeTags(new Set());
    setExcludeTags(new Set());
    props.setTagFilters([], [], useAndTags);
  }

  const applyFilters = () => {
    props.setTagFilters(includeTags, excludeTags, useAndTags);
  }

  return <>
          <div className="navtree-filter-actions">
            <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                <Button onClick={applyFilters}>Apply Filter</Button>
                <Button onClick={clearFilters}>Clear Filter</Button>
            </div>
          </div>

          <div className='navtree-filter-tags phenom-content-scrollable'>
            <div role="Include tags" className='navtree-filter-list'>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 4 }}>
                <h2>Include:</h2>
                <PhenomToggle data={["OR", "AND"]}
                              checked={useAndTags}
                              onChange={() => setUseAndTags(!useAndTags)}
                              style={{ "--toggle-width": "55px", "--toggle-height": "20px" }}
                              data-tip="Query Type"
                              data-for="hoverTip" />
              </div>

              <PhenomComboBox data={tagList}
                              id="filter-menu-include-tag"
                              placeholder="Select a tag"
                              onChange={addIncludeFilter} />
              <ul>
                {[...includeTags].map((tag) => (
                  <li key={tag}>
                    <button onClick={() => removeIncludeFilter(tag)}>
                      <i className='fas fa-tag fa-fw' /> 
                      <span>{tag}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div role="Exclude tags" className='navtree-filter-list'>
              <div style={{ display: "flex", height: 26, alignItems: "center", marginBottom: 4 }}>
                <h2>Exclude:</h2>
              </div>

              <PhenomComboBox data={tagList}
                              id="filter-menu-exclude-tag"
                              placeholder="Select a tag"
                              onChange={addExcludeFilter} />
              <ul>
                {[...excludeTags].map((tag) => (
                  <li key={tag}>
                    <button onClick={() => removeExcludeFilter(tag)}>
                      <i className='fas fa-tag fa-fw' /> 
                      <span>{tag}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
}





const measurementBranch = {
  text: "Measurement",
  items: [
    { text: "Measurement",                    xmiType: "logical:Measurement" },
    { text: "Measurement System",             xmiType: "logical:MeasurementSystem" },
    { text: "Measurement System Axis",        xmiType: "logical:MeasurementSystemAxis" },
    { text: "Standard Measurement System",    xmiType: "logical:StandardMeasurementSystem" },
    { text: "Measurement Conversion",         xmiType: "logical:MeasurementConversion" },
    { text: "Measurement System Conversion",  xmiType: "logical:MeasurementSystemConversion" },
  ],
};

const coordinateSystemBranch = {
  text: "Coordinate System",
  items: [
    { text: "Coordinate System",            xmiType: "logical:CoordinateSystem" },
    { text: "Coordinate System Axis",       xmiType: "logical:CoordinateSystemAxis" },
  ],
};

const platformConstraintBranch = {
  text: "Constraints",
  items: [
    { text: "Regular Expression Constraint",  xmiType: "platform:RegularExpressionConstraint" },
    { text: "Real Range Constraint",          xmiType: "platform:RealRangeConstraint" },
    { text: "Integer Range Constraint",       xmiType: "platform:IntegerRangeConstraint" },
  ]
};

const logicalConstraintBranch = {
  text: "Constraints",
  items: [
    { text: "Regular Expression Constraint",  xmiType: "logical:RegularExpressionConstraint" },
    { text: "Real Range Constraint",          xmiType: "logical:RealRangeConstraint" },
    { text: "Integer Range Constraint",       xmiType: "logical:IntegerRangeConstraint" },
    { text: "Enumeration Constraint",         xmiType: "logical:EnumerationConstraint" },
  ]
};

const conceptualBranch = {
  text: "Conceptual",
  items: [
    { text: "Conceptual Package",  xmiType: "face:ConceptualDataModel", },
    { text: "Entity",              xmiType: "conceptual:Entity" },
    { text: "Association",         xmiType: "conceptual:Association" },
    { text: "Composition",         xmiType: "conceptual:Composition" },
    { text: "Participant",         xmiType: "conceptual:AssociatedEntity" },
    { text: "Observable",          xmiType: "conceptual:Observable" },
  ]
};

const valueTypeBranch = {
  text: "Value Type",
  items: [
    { text: "Boolean",  xmiType: "logical:Boolean", },
    { text: "Character",  xmiType: "logical:Character", },
    { text: "Enumerated",  xmiType: "logical:Enumerated", },
    { text: "Integer",  xmiType: "logical:Integer", },
    { text: "Natural",  xmiType: "logical:Natural", },
    { text: "NonNegativeReal",  xmiType: "logical:NonNegativeReal", },
    { text: "Real",  xmiType: "logical:Real", },
    { text: "String",  xmiType: "logical:String", },
  ]
}


const logicalBranch = {
  text: "Logical",
  items: [
    { text: "Logical Package", xmiType: "face:LogicalDataModel" },
    measurementBranch,
    coordinateSystemBranch,
    { text: "Landmark",         xmiType: "logical:Landmark" },
    { text: "Reference Point",  xmiType: "logical:ReferencePoint" },
    { text: "Value Type Unit",  xmiType: "logical:ValueTypeUnit" },
    { text: "Unit",             xmiType: "logical:Unit" },
    valueTypeBranch,
    logicalConstraintBranch,
  ]
};

const platformBranch = {
  text: "Platform",
  items: [
    { text: "Platform Package",  xmiType: "face:PlatformDataModel" },
    { text: "View",              xmiType: "platform:View" },
    { text: "Enumeration",       xmiType: "platform:Enumeration" },
    { text: "IDL Struct",        xmiType: "platform:IDLStruct" },
    platformConstraintBranch,
  ]
};

const diagramBranch = {
  text: "Diagram",
  items: [
    { text: "Diagram Package",  xmiType: "skayl:DiagramModel" },
    { text: "Diagram Context",  xmiType: "skayl:DiagramContext" },
  ]
};

const uopBranch = {
  text: "UoP",
  items: [
    { text: "UoP Package",                 xmiType: "face:UoPModel" },
    { text: "Portable Component",          xmiType: "uop:PortableComponent" },
    { text: "Message Port",                xmiType: "uop:MessagePort" },
    { text: "Platform Specific Component", xmiType: "uop:PlatformSpecificComponent" },
  ]
}

const integrationBranch = {
  text: "Integration Model",
  items: [
    { text: "Integration Package", xmiType: "skayl:IntegrationModel", },
    { text: "Transport Channel",   xmiType: "im:TransportChannel" },
    { text: "UoP Instance",        xmiType: "im:UoPInstance" },
    { text: "Integration Context", xmiType: "im:IntegrationContext" },
    { text: "Source",              xmiType: "im:SourceNode" },
    { text: "Sink",                xmiType: "im:SinkNode" },
    { text: "FanIn",               xmiType: "im:FanIn" },
    { text: "Transform",           xmiType: "im:TransformNode" },
    { text: "Filter",              xmiType: "im:FilterNode" },
    { text: "Transporter",         xmiType: "im:ViewTransporterNode" },
    { text: "Sim Adapter",         xmiType: "im:SIMAdapter" },
    { text: "Queuing Adapter",     xmiType: "im:QueuingAdapter" },
    { text: "Data Pump",           xmiType: "im:DataPump" },
  ]
};


const deploymentBranch = {
  text: "Deployment Model",
  items: [
    { text: "Deployment Package", xmiType: "skayl:DeploymentModel", },
    { text: "Main Program",       xmiType: "ddm:MainProgram" },
    { text: "Processing Element", xmiType: "pedm:ProcessingElement" },
  ]
};

const messageBranch = {
  text: "Message Data Model",
  items: [
    { text: "Message Package", xmiType: "skayl:MessageDataModel", },
    { text: "Message Type",    xmiType: "message:Type" },
  ]
};

const dataModeTree = [ conceptualBranch, logicalBranch, platformBranch, diagramBranch ];
const integrationModeTree = [ uopBranch, integrationBranch, deploymentBranch, messageBranch ];





class FilterByType extends React.Component {
  state = {
    selection: new Set(),
  }

  componentDidMount() {
    this.setState({ selection: this.props.includeXmiTypes })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.includeXmiTypes !== this.props.includeXmiTypes) {
      this.setState({ selection: this.props.includeXmiTypes })
    }
  }

  isSelected = (xmiType) => {
    return this.state.selection.has(xmiType);
  }

  toggleSelection = (leaf) => {
    if (!leaf?.xmiType) return;
    const selection = new Set(this.state.selection);
    selection.has(leaf.xmiType) ? selection.delete(leaf.xmiType) : selection.add(leaf.xmiType);
    this.setState({ selection });
  }

  toggleCheckbox = (e, leaf) => {
    const selection = new Set(this.state.selection);

    const queue = [leaf];
    while (queue.length) {
      const ele =  queue.shift();
      if (ele.xmiType) {
        e.target.element.current.checked ? selection.add(ele.xmiType) : selection.delete(ele.xmiType);
      }
      Array.isArray(ele.items) && ele.items.forEach(item => queue.push(item))
    }
    this.setState({ selection });
  }

  clearFilters = () => {
    this.props.setXmiTypeFilters([]);
  }

  applyFilters = () => {
    this.props.setXmiTypeFilters(this.state.selection)
  }

  render() {
    return <>
            <div style={{ display: "flex", justifyContent: "center", gap: 10, margin: "1em auto" }}>
                <Button onClick={this.applyFilters} style={{ fontSize: 12 }}>Apply Filter</Button>
                <Button onClick={this.clearFilters} style={{ fontSize: 12 }}>Clear Filter</Button>
            </div>
            <div className="phenom-tree">
              <ul>
                {this.props.mode !== "integration" && dataModeTree.map((leaf, idx) => {
                  return <FilterTypeLeaf leaf={leaf}
                                          isSelected={this.isSelected}
                                          toggleSelection={this.toggleSelection}
                                          toggleCheckbox={this.toggleCheckbox} />
                })}

                {this.props.mode !== "edit" && integrationModeTree.map((leaf, idx) => {
                  return <FilterTypeLeaf leaf={leaf}
                                         isSelected={this.isSelected}
                                         toggleSelection={this.toggleSelection}
                                         toggleCheckbox={this.toggleCheckbox} />
                })}
              </ul>
            </div>
    </>
  }
}



class FilterTypeLeaf extends React.Component {
  state = {
    collapsed: false,
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }))
  }

  isChecked = () => {
    const { leaf, isSelected } = this.props;

    const types = [];
    const queue = [leaf];
    while (queue.length) {
      const ele = queue.shift();
      ele.xmiType && types.push(ele.xmiType);
      Array.isArray(ele.items) && ele.items.forEach(item => queue.push(item));
    }

    if (Array.isArray(leaf.items)) {
      return types.every(type => isSelected(type));
    }
    return isSelected(leaf.xmiType);
  }

  render() {
    const { leaf, isSelected, toggleSelection, toggleCheckbox } = this.props;
    const { collapsed } = this.state;
    const hasChildren = Array.isArray(leaf.items) && leaf.items.length > 0;

    return <li>
            <div className={"leaf-node" + (isSelected(leaf.xmiType) ? " selected" : "")}>
              {hasChildren &&
                <span className={"leaf-collapse k-icon " + (collapsed ? "k-i-expand" : "k-i-collapse")}
                      onClick={this.toggleCollapse} /> 
              }

              <Checkbox label="" checked={this.isChecked()} onChange={(e) => toggleCheckbox(e, leaf)} />

              <span className="leaf-node-text" onClick={() => toggleSelection(leaf)}>{leaf?.text}</span>
            </div>
            
            {hasChildren && !collapsed && <ul>
              {this.props.leaf.items.map((ele, idx) => {
                return <FilterTypeLeaf key={ele.xmiType || idx}
                                       chainUncheckUp={this.chainUncheckUp}
                                       {...this.props}
                                       leaf={ele} />
              })}
            </ul> }
          </li>
  }
}



export default TreeFilter;
