import {EditPackageManager} from "../edit/edit-package-manager";
import {EditEntityManager} from "../edit/edit_entity/edit-entity-manager";
import {EditViewManager} from "../edit/edit-view-manager";
import {EditCharacteristicManager} from "../edit/edit-characteristic-manager";
import {EditPortableComponentManager} from "../edit/edit-portable-component-manager";
import {EditObservableManager} from "../edit/edit-observable";
import {EditLandmarkManager} from "../util/landmark";
import {EditMeasurementManager} from "../edit/edit-measurement-manager";
import {EditMeasurementAxisManager} from "../edit/edit-measurement-axis-manager";
import {EditConversionManager} from "../edit/conversion";
import {EditMeasurementSystemManager} from "../edit/edit-measurement-system";
import {EditMeasurementSystemAxisManager} from "../util/measurement_system_axis";
import {EditCoordinateSystemManager} from "../edit/edit-coordinate-system";
import {EditPlatformTypeManager} from "../edit/edit-platform-type";
import {EditCoordinateSystemAxisManager} from "../util/coordinate_system_axis";
import {EditMessageManager} from "../edit/edit-message-manager";
import {EditUnitManager} from "../edit/edit-unit-manager";
import {EditConstraintManager2} from "../edit/edit-constraint-manager";
import {EditReferencePointManager} from "../edit/edit-referencepoint";
import {EditValueTypeManager} from "../edit/edit-valuetype-manager";
import {EditValueTypeUnitManager} from "../edit/edit-valuetypeunit-manager";
import {EditBasisEntityManager} from "../edit/edit-basis-entity-manager";
import {EditDomainManager} from "../edit/edit-domain-manager";
import { Route, Redirect, Switch } from "react-router-dom";
import React from "react";
import { getNodesOfType } from "../../requests/sml-requests";
import {EditUOPInstanceManager} from "../edit/integration/EditUOPInstance";
import {EditTransportChannelManager} from "../edit/integration/EditTransportChannel";
import {EditMainProgramManager} from "../edit/integration/EditMainProgram";
import {EditProcessingElementManager} from "../edit/integration/EditProcessingElement";
import {EditStandardMeasurementSystemManager} from "../util/standard_measurement_system";
import NavTree from "../tree/NavTree";
import ViewModification from "../../components/edit/view-modification";
import { _ajax } from "../../requests/sml-requests";
import { routeUrlBuilder } from "../../routes";


export class Details extends React.Component {
    constructor(props) {
        super(props);
        window["cachedRequest"] = (request) => {
            return this.cache[request];
        };

        window["resetCache"] = () => {
            this.fetchModelData();
            return Promise.all([this.cache.measurementData, this.cache.newVTUS]);
        };

        this.cache = {};
    }

    UNSAFE_componentWillMount() {
        this.fetchModelData();
    }

    componentDidMount() {
      NavTree.collapseNavTree(false);
      switch (this.props.match.params.mode) {
        case "integration":
          NavTree.assignPresetPageFilters("integration");
          break;

        case "edit":
        default:
          NavTree.assignPresetPageFilters("data");
      }
    }

    componentWillUnmount() {
      NavTree.clearPageFilters();
    }

    fetchModelData() {
        this.cache.measurementData = _ajax({
            url: "/index.php?r=/measurement/model-nodes-of-type",
            method: "get",
            data: {
                coreAddenda: ["measurementAxisMULTI", "measurementSystem", "childrenMULTI", "addEnumerated", "addIdlStruct"],
                coreAddendaChildren: ["type"],
                typeAddenda: ["realizationsMULTI", "measurementAxisMULTI"],
                measurementAxisAddenda: ["realizationsMULTI", "measurementSystemAxis"],
                measurementSystemAddenda: ["coordinateSystem", "measurementSystemAxisMULTI"],
                measurementSystemAxisAddenda: ["axis"]
            }
        });
        if (!this.cache.observables) {
            this.cache.observables = getNodesOfType("conceptual:Observable");
        }

        this.cache.newVTUS = _ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: [
                    "logical:ValueTypeUnit",
                    "logical:Unit",
                    "logical:Enumerated",
                    "logical:Boolean",
                    "logical:Character",
                    "logical:Integer",
                    "logical:Natural",
                    "logical:NonNegativeReal",
                    "logical:Real",
                    "logical:String"
                ],
                coreAddenda: ["childrenMULTI", "realizationsMULTI"],
                coreAddendaChildren: ["allowedValueMULTI"],
            }
        }).then(res => {
            const response = res.data;
            const units = {};
            const value_types = {};
            const value_type_units = {};

            response.nodes.forEach(node => {
                if (node.xmiType === "logical:ValueTypeUnit") {
                    value_type_units[node.guid] = node;
                } else if (node.xmiType === "logical:Unit") {
                    units[node.guid] = node;
                } else {
                    value_types[node.guid] = node;
                }
            });
            Object.values(value_type_units).forEach(vtu => {
                vtu.unit = units[vtu.unit];
                vtu.valueType = value_types[vtu.valueType];
            });
            return {value_type_units: value_type_units, value_types: value_types, units: units};
        });

    }

    render() {
      return <div className="phenom-content-wrapper">
              <Switch>
                <Route component={EditPackageManager}
                       path={routeUrlBuilder(null, "details", "package", ":guid", ":parentGuid?")} />
                <Route component={EditEntityManager}
                       path={routeUrlBuilder("edit", "details", "entity", ":guid")} />
                <Route component={EditViewManager}
                       path={routeUrlBuilder("edit", "details", "view", ":guid", ":parentGuid?")}/>
                <Route component={EditCharacteristicManager}
                       path={routeUrlBuilder("edit", "details", "characteristic", ":guid", ":parentGuid?", ":parentName?")}/>
                <Route component={EditPortableComponentManager}
                       path={routeUrlBuilder("integration", "details", "portable_component", ":guid")} />
                <Route component={EditObservableManager}
                       path={routeUrlBuilder("edit", "details", "observable", ":guid")} />
                <Route component={EditMeasurementManager}
                       path={routeUrlBuilder("edit", "details", "measurement", ":guid")} />
                <Route component={EditMeasurementAxisManager}
                       path={routeUrlBuilder("edit", "details", "measurement_axis", ":guid")} />
                <Route component={EditMessageManager}
                       path={routeUrlBuilder("edit", "details", "message", ":guid")} />
                <Route component={EditConstraintManager2}
                       path={routeUrlBuilder("edit", "details", "constraint", ":guid")} />
                <Route component={EditTransportChannelManager}
                       path={routeUrlBuilder("integration", "details", "transport_channel", ":guid")} />
                <Route component={EditUOPInstanceManager}
                       path={routeUrlBuilder("integration", "details", "uop_instance", ":guid")} />
                <Route component={EditMainProgramManager}
                       path={routeUrlBuilder("integration", "details", "main_program", ":guid")} />
                <Route component={EditProcessingElementManager}
                       path={routeUrlBuilder("integration", "details", "processing_element", ":guid")} />
                <Route component={EditUnitManager}
                       path={routeUrlBuilder("edit", "details", "unit", ":guid")} />
                <Route component={EditPlatformTypeManager}
                       path={routeUrlBuilder("edit", "details", "platform_type", ":guid")} />
                <Route component={EditReferencePointManager}
                       path={routeUrlBuilder("edit", "details", "reference_point", ":guid", ":parentGuid?", ":parentName?")} />
                <Route component={EditMeasurementSystemManager}
                       path={routeUrlBuilder("edit", "details", "measurement_system", ":guid")} />
                <Route component={EditMeasurementSystemAxisManager}
                       path={routeUrlBuilder("edit", "details", "measurement_system_axis", ":guid")} />
                <Route component={EditConversionManager}
                       path={routeUrlBuilder("edit", "details", "conversion", ":guid")} />
                <Route component={EditCoordinateSystemManager}
                       path={routeUrlBuilder("edit", "details", "coordinate_system", ":guid")} />
                <Route component={EditCoordinateSystemAxisManager}
                       path={routeUrlBuilder("edit", "details", "coordinate_system_axis", ":guid")} />
                <Route component={EditLandmarkManager}
                       path={routeUrlBuilder("edit", "details", "landmark", ":guid")} />
                <Route component={EditStandardMeasurementSystemManager}
                       path={routeUrlBuilder("edit", "details", "standard_measurement_system", ":guid")} />
                <Route component={EditValueTypeManager}
                       path={routeUrlBuilder("edit", "details", "value_type", ":guid")} />
                <Route component={EditValueTypeUnitManager}
                       path={routeUrlBuilder("edit", "details", "value_type_unit", ":guid")} />
                <Route component={EditBasisEntityManager}
                       path={routeUrlBuilder("edit", "details", "basis_entity", ":guid")} />
                <Route component={EditDomainManager}
                       path={routeUrlBuilder("edit", "details", "domain", ":guid")} />
                <Route component={ViewModification}
                       path={routeUrlBuilder("edit", "details", "modify_view", ":guid", ":viewGuid1", ":viewGuid2")} />
                <Route component={BlankDetail} 
                       exact path={routeUrlBuilder(null, "details")} />
                <Redirect to="/edit/details" />
              </Switch>
            </div>
    }
}



const BlankDetail = (props) => {
  return <div>
          <nav className="sub-menu-actions flex-end" aria-label='form actions'/>
          <div style={{color: "#565656", height: 512, padding: "1em"}}>Double click a node in the tree to view or edit it.</div>
        </div>
}
